<template>
  <div v-if="users1.length > 0 || users2.length > 0">
    <div class="container hotTitle" v-if="users1.length > 0 || users2.length > 0">
      <h3>{{ this.$store.state.translation[this.$store.state.lang].lastaccounts.title }}</h3>
    </div>

    <div class="grid-wrapper">
      <div class="container topCollection">
        <table class="topCollectionColumn" v-if="users1.length > 0">
          <thead>
          <tr class="maintext title">
            <th>{{ this.$store.state.translation[this.$store.state.lang].hotaccounts.collection }}</th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="user of users1"
              :key="'name' + user.wallet"
              class="subtitle rowhover"
              v-on:click="$router.push(`/${user.address}`)"
          >
            <td class="row">
              <div class="img">
                <img v-if="user.profile_image" :src="user.profile_image" />
                <div class="loadImage" v-else></div>
              </div>
              {{ user.username }}
            </td>
          </tr>
          </tbody>
        </table>

        <table class="topCollectionColumn" v-if="users2.length > 0">
          <thead class="mobile_disabling">
          <tr class="maintext title">
            <th>
              {{ this.$store.state.translation[this.$store.state.lang].hotaccounts.collection }}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="user of users2"
              :key="'name' + user.wallet"
              class="subtitle rowhover"
              v-on:click="$router.push(`/${user.address}`)"
          >
            <td class="row">
              <div class="img">
                <img v-if="user.profile_image" :src="user.profile_image" />
                <div class="loadImage" v-else></div>
              </div>
              {{ user.username }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <VueAwesomePaginate
          v-if="users1.length > 0"
          :total-items="totalItems"
          :items-per-page="itemsPerPage"
          :max-pages-shown="maxPagesShown"
          v-model="currentPage"
          :on-click="pageClickHandler"
          paginate-buttons-class="btn"
          active-page-class="btn-active"
          back-button-class="back-btn"
          next-button-class="next-btn"
      />
    </div>
  </div>
</template>

<script>
import { VueAwesomePaginate } from "vue-awesome-paginate";
import { ref } from "vue";
import connect from "../../config.crypto";

export default {
    name: "HotAccounts",
    components: {
        VueAwesomePaginate,
    },
    data() {
        return {
            currentPage: ref(1),
            totalItems: 0,
            itemsPerPage: 10,
            maxPagesShown: 5,
            users1: [],
            users2: [],
            tokenName: connect.wallets[0].tokenName[connect.wallets[0].chain[0]],
        };
    },

    methods: {
        async requestData(method, bodyItem, func, code) {
            const body = JSON.stringify(bodyItem);
            let dataRequest = undefined;
            if (method == "get") {
                dataRequest = {
                    method: method,
                    headers: {
                        "content-type": "application/json",
                    },
                };
            } else if (method == "post") {
                dataRequest = {
                    method: method,
                    headers: {
                        "content-type": "application/json",
                    },
                    body: body,
                };
            }
            if (dataRequest) {
                let response = await fetch(`${process.env.VUE_APP_SERVER}/api/${func}`, dataRequest);
                if (response.ok) {
                    if (code == "json") {
                        try {
                            return await response.json();
                        } catch {
                            return false;
                        }
                    } else {
                        try {
                            return await response.text();
                        } catch {
                            return false;
                        }
                    }
                } else {
                    console.log("Error HTTP: " + response.status);
                }
            } else {
                console.log("request method not found");
            }
        },

        async getData(page = 1) {
            const limit = this.itemsPerPage;
            const topUsers = await this.requestData("get", {}, `user/lastuser?limit=${limit}&page=${page}`, "json");

            this.users1 = topUsers.rows.slice(
                0,
                topUsers.rows.length < 10 && topUsers.rows.length > 5 ? Math.ceil(topUsers.rows.length / 2) : 5
            );
            this.users2 = topUsers.rows.slice(
                topUsers.rows.length < 10 && topUsers.rows.length > 5 ? Math.ceil(topUsers.rows.length / 2) : 5,
                topUsers.rows.length < 10 && topUsers.rows.length > 5 ? topUsers.rows.length : 10
            );
            this.totalItems = topUsers.count;
        },

        async getImage(id) {
            const createrData = await this.requestData("get", {}, `image/${id}`, "json");
            if (createrData) {
                return createrData[0].image;
            } else {
                return false;
            }
        },

        pageClickHandler(page) {
            this.getData(page);
        },
    },

    mounted() {
        this.getData();
    },

    beforeUnmount() {},
};
</script>

<style scoped>
.hotTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 4rem auto 2.1875rem auto;
  text-transform: uppercase;
}

.hotTitle {
  margin: 4rem auto 1rem auto;
}

.topCollection {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.topCollectionColumn {
    width: 43%;
}
.topCollectionColumn th {
    text-align: left;
}

.topCollectionColumn tbody {
    margin-top: 3rem;
}
.topCollectionColumn .maintext.title {
    color: var(--color-4);
    display: grid;
    margin-bottom: 16px;
}

.topCollectionColumn .subtitle {
    display: grid;
    align-items: center;
}

.img {
    border: 0.5px solid #9f9f9f;
    border-radius: 20px;
    width: 3.75rem;
    height: 3.75rem;
    overflow: hidden;
}

.topCollectionColumn .loadImage,
.topCollectionColumn img {
    transition: all 0.3s;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.row {
    width: 100%;
    margin: 0;
    clear:both;
    display: block;
    height: 5rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1.375rem;
}

.rowhover {
    transition: all 0.2s;
    cursor: pointer;
}

.rowhover:hover {
    opacity: 0.8;
}

.rowhover:hover .img img {
    transform: scale(1.2);
}

.index {
    width: 2rem;
    text-align: center;
}

.maxWidth {
    width: 100% !important;
}

@media screen and (max-width: 1200px) {
    /* .displaynone {
        display: none;
    } */

    .topCollectionColumn {
        width: 47%;
    }
}

@media screen and (max-width: 950px) {
    .displaynone {
        display: none;
    }

    .topCollection {
        flex-direction: column;
    }

    .mobile_disabling {
        display: none;
    }

    .topCollectionColumn {
        width: 100%;
    }
}
</style>
