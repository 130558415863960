<template>
    <div
        class="navBar"
        v-bind:class="{ navBarNoOpacity: !navOpacity && !this.$store.state.navActive, navBarOpasity: navOpacity }"
    >
        <div class="container">
            <div class="logotext" v-on:click="$router.push('/')">Place Of Good</div>

            <Transition>
                <div class="search" v-show="!this.$store.state.navActive">
                    <svg
                        class="searchSvg"
                        width="18"
                        height="19"
                        viewBox="0 0 18 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M14.7029 7.5C14.7029 11.3628 11.5494 14.5 7.65224 14.5C3.75508 14.5 0.601562 11.3628 0.601562 7.5C0.601562 3.63718 3.75508 0.5 7.65224 0.5C11.5494 0.5 14.7029 3.63718 14.7029 7.5Z"
                            stroke="#999999"
                        />
                        <path d="M12.6855 13L17.216 18.5" stroke="#999999" stroke-linecap="round" />
                    </svg>

                    <div class="selected-city" v-if="selectedCity">
                      {{ selectedCity.name }}
                    </div>

                    <input
                        class="subtext2"
                        v-model="input"
                        v-on:input="searchText"
                        :placeholder="this.$store.state.translation[this.$store.state.lang].searchPlaceholder"
                    />

                    <Transition>
                        <div
                            v-if="show && (this.searchData.collections.length > 0 || this.searchData.users.length > 0)"
                            class="searchDropDown"
                        >
                            <div v-if="this.searchData.collections.length > 0">
                                <div class="subtext2">
                                    {{ this.$store.state.translation[this.$store.state.lang].search.collection }}
                                </div>

                                <div class="searchBlockOut">
                                    <div
                                        class="searchBlockOutItem"
                                        v-for="item of searchData.collections"
                                        v-bind:key="item"
                                        v-on:click="
                                            show = false;
                                            $router.replace({
                                                name: 'item',
                                                params: {
                                                    chainId: item.chain_id,
                                                    wallet: item.address,
                                                    id: item.blockchain_id,
                                                },
                                            });
                                        "
                                    >
                                        {{ item.name }}
                                    </div>
                                </div>
                            </div>
                            <div v-if="this.searchData.users.length > 0">
                                <div class="subtext2">
                                    {{ this.$store.state.translation[this.$store.state.lang].search.profile }}
                                </div>

                                <div class="searchBlockOut">
                                    <div
                                        class="searchBlockOutItem"
                                        v-for="item of searchData.users"
                                        v-bind:key="item"
                                        v-on:click="
                                            show = false;
                                            $router.push(`/${item.address}`);
                                        "
                                    >
                                        {{ item.username || item.address }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition>

                    <Transition>
                        <svg
                            v-show="input != '' || show"
                            v-on:click="input = ''; show = false"
                            class="searchCancel"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M12 1.5C6.21 1.5 1.5 6.21 1.5 12C1.5 17.79 6.21 22.5 12 22.5C17.79 22.5 22.5 17.79 22.5 12C22.5 6.21 17.79 1.5 12 1.5ZM12 21.5C6.762 21.5 2.5 17.238 2.5 12C2.5 6.762 6.762 2.5 12 2.5C17.238 2.5 21.5 6.762 21.5 12C21.5 17.238 17.238 21.5 12 21.5ZM15.354 9.354L12.707 12L15.353 14.646C15.548 14.841 15.548 15.158 15.353 15.353C15.255 15.451 15.127 15.499 14.999 15.499C14.871 15.499 14.743 15.45 14.645 15.353L12 12.707L9.354 15.353C9.256 15.451 9.128 15.499 9 15.499C8.872 15.499 8.744 15.45 8.646 15.353C8.451 15.158 8.451 14.841 8.646 14.646L11.292 12L8.646 9.354C8.451 9.159 8.451 8.84197 8.646 8.64697C8.841 8.45197 9.158 8.45197 9.353 8.64697L11.999 11.293L14.645 8.64697C14.84 8.45197 15.157 8.45197 15.352 8.64697C15.547 8.84197 15.549 9.158 15.354 9.354Z"
                                fill="#25314C"
                            />
                        </svg>
                    </Transition>

                  <Transition>
                    <i @click="$refs.cities.open(false); $refs.tags.open()"
                       class="ri-price-tag-3-line filter"></i>
                  </Transition>

                  <Transition>
                    <i @click="$refs.tags.open(false); $refs.cities.open()"
                       class="ri-map-pin-line filter"></i>
                  </Transition>
                </div>
            </Transition>

            <NavButtons :mobile="true" />
            <div class="navServes displaynone">
                <Toggle />
                <SelectLang />

                <button @click="$emit('city')" class="city-select" v-if="this.user?.city?.name">
                  <i class="ri-map-pin-line"></i>
                  {{ this.user.city.name }}
                </button>
            </div>
        </div>

      <filter-search-city-modal ref="cities" @search="searchText"
                                @select="(city) => selectedCity = city" />
      <filter-search-tags-modal ref="tags" @search="searchText" />
    </div>
</template>

<script>
import Toggle from "./Toggle.vue";
import SelectLang from "./Select.vue";
import NavButtons from "./NavButtons.vue";
import FilterSearchCityModal from "@/components/FilterSearchCityModal.vue";
import FilterSearchTagsModal from "@/components/FilterSearchTagsModal.vue";
import axios from "axios";
import {mapState} from "vuex";

export default {
    name: "NavBar",
    components: {
        Toggle,
        SelectLang,
        NavButtons,
        FilterSearchCityModal,
        FilterSearchTagsModal,
    },

    data() {
        return {
            input: "",
            navOpacity: true,
            show: false,
            user: {},

           selectedCity: null,
            searchData: {
                collections: [],
                users: [],
            },
        };
    },


    computed: mapState(['lang']),
    watch: {
      async lang() {
        if (this.user?.city_id) {
          this.user.city = await this.getCityFromId(this.user.city_id);
        }
      },
      $route() {
        this.opacityNav();
      },
    },
  async created() {
    await setTimeout(async () => {
      this.user = await this.getUser();

      if (this.user?.city_id) {
        this.user.city = await this.getCityFromId(this.user.city_id);
      }
    }, 500);
  },
  methods: {
        async setUserCity(city_id) {
          if (!this.user) {
            return;
          }

          this.user.city_id = city_id;
          this.user.city = await this.getCityFromId(city_id);
        },
        opacityNav() {
            let posTop =
                window.pageYOffset !== undefined
                    ? window.pageYOffset
                    : (document.documentElement || document.body.parentNode || document.body).scrollTop;
            if (this.$route.path == "/") {
                if (posTop < 80) {
                    this.navOpacity = true;
                } else {
                    this.navOpacity = false;
                }
            } else {
                this.navOpacity = false;
            }
        },
        async searchText() {
            this.searchData =
                await axios.get(`${process.env.VUE_APP_SERVER}/api/search`, {
                  params: {
                    search: this.input,
                    limit: 3,
                    city_id: this.selectedCity?.geonameId,
                    tags_id: this.$refs?.tags.selectedTags
                  }
                }).then(({ data }) => {
                  this.show = true;
                  return data;
                });
        },
        async getUser() {
          return await axios.get(`${process.env.VUE_APP_SERVER}/api/user/getuser/${this.$store.state.wallet.address.toLowerCase()}`, {
            params: {
              sender: this.$store.state.wallet.address.toLowerCase()
            }
          }).then(({ data }) => {
            return data;
          })
        },
        async getCityFromId(city_id) {
          const lang = this.$store.state.lang;
          return await axios.get(`${process.env.VUE_APP_SERVER}/api/tag/cities/${city_id}`, {
            params: {
              lang,
            }
          }).then(({ data }) => {
            return data;
          }).catch(error => {
            console.error('Произошла ошибка при запросе к geonames API:', error);
          });
        },
      },

    mounted() {
        this.opacityNav();
        document.addEventListener("scroll", this.opacityNav);
    },

    beforeUnmount() {
        document.removeEventListener("scroll", this.opacityNav);
    },
};
</script>

<style scoped>
.navBar {
    transition: background 0.2s;
}

.logotext {
    transition: all 0.2s;
    cursor: pointer;
}

.navBarNoOpacity {
    background: var(--sub-bg-color-2);
    backdrop-filter: blur(15px);
    width: 100%;
}

.navBarOpasity {
    background: rgba(255, 255, 255, 0);
    backdrop-filter: blur(0);
}

.navBarNoOpacity .logotext {
    color: var(--main-color);
}

.navBarOpasity .logotext {
    color: #ffffff;
}

.navBar .container {
    padding: 1.25rem 4%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2%;
    filter: drop-shadow(0px 4px 50px rgba(0, 0, 0, 0.1));
}

.search {
    background: var(--sub-bg-color);
    backdrop-filter: blur(2px);
    border-radius: 10px;

    display: flex;
    align-items: center;
    gap: 0.625rem;
    padding: 0 0.875rem;

    width: 100%;
    height: 2.875rem;

    position: relative;
}

.searchDropDown {
    position: absolute;
    left: 0;
    top: 3.5rem;
    width: 100%;
    background: var(--sub-bg-color);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    padding: 0.5rem 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.searchDropDown .subtext2 {
    color: var(--color-5);
    padding: 0 0.875rem 0.25rem 0.875rem;
}

.searchBlockOutItem {
    cursor: pointer;
    padding: 0.2rem 0.875rem;
    transition: all 0.2s;
}

.searchBlockOutItem:hover {
    opacity: 0.7;
}

.searchBlockOutItem:active {
    opacity: 0.4;
}

.search input {
    border: solid 0;
    background: rgba(255, 255, 255, 0);
    width: 100%;
}

.searchSvg {
    min-width: 1.06875rem;
    height: 1.15625rem;
    margin-right: 0.4rem;
}

.searchCancel {
    cursor: pointer;
    height: 1.3rem;
    width: 1.3rem;
    border-radius: 10rem;
}

.filter {
  cursor: pointer;
  font-size: 18px;
}

.searchCancel path {
    fill: var(--main-color);
}

.navServes {
    display: flex;
    align-items: center;
    gap: 0.625rem;
}

.v-enter-active,
.v-leave-active {
    transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}

.city-select {
  background-color: var(--sub-bg-color);
  padding: 8px 16px;
  border-radius: 10px;
  box-sizing: border-box;
  white-space: nowrap;
  height: 2.875rem;
  display: flex;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  gap: 10px;
  transition: all 0.2s;
}

.city-select:hover {
  opacity: .8;
}

.city-select i {
  font-size: 18px;
}

.selected-city {
  font-size: 14px;
  white-space: nowrap;
}

@media screen and (max-width: 950px) {
    .navBar .container {
        gap: 1%;
    }

    .nuvButtons {
        gap: 0.4rem;
    }

    .navServes {
        gap: 0.4rem;
    }
}

@media screen and (max-width: 850px) {
    .displaynone {
        display: none;
    }

    .navBar .container {
        gap: 2%;
    }
}

@media screen and (max-width: 550px) {
    .search {
        gap: 0.3rem;
        padding: 0 0.5rem;
        height: 2.5rem;
    }
}
</style>
