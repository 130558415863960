<template>
    <div class="app" v-bind:class="{ 'light-theme': !this.$store.state.theme, 'dark-theme': this.$store.state.theme }">
        <NavBar class="navBarMain" ref="navigationBar" @city="$refs.requestCity.requestGeoPosition()" />
        <router-view v-slot="{ Component }">
            <transition>
                <component :is="Component" />
            </transition>
        </router-view>
        <vFooter />
        <Transition>
            <div v-if="this.$store.state.navActive" class="mobileMenu">
                <div class="connectBlock dropDownWindowHeader">
                    <h4>
                        {{
                            this.$store.state.wallet.address == ""
                                ? this.$store.state.translation[this.$store.state.lang].common.connectTo
                                : this.$store.state.wallet.address.slice(0, 6) +
                                  "..." +
                                  this.$store.state.wallet.address.slice(-4)
                        }}
                    </h4>
                    <div v-on:click="connectWallet" class="blueButton">
                        {{
                            this.$store.state.wallet.address == ""
                                ? this.$store.state.translation[this.$store.state.lang].common.connect
                                : this.$store.state.translation[this.$store.state.lang].common.disconnect
                        }}
                    </div>
                </div>
                <Toggle />
                <SelectLang />


                <button @click="$refs.requestCity.requestGeoPosition()" class="city-select" v-if="$refs.navigationBar?.user?.city?.name">
                  <i class="ri-building-line"></i>
                  {{ $refs.navigationBar?.user?.city?.name }}
                </button>
            </div>
        </Transition>

      <request-city-modal ref="requestCity" @change="setUserCity" />
    </div>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import vFooter from "./components/Footer.vue";
import Toggle from "./components/Toggle.vue";
import SelectLang from "./components/Select.vue";
import RequestCityModal from "@/components/RequestCityModal.vue";

import {
    switchChain,
    getAccount,
} from "kasatkin-crypto-core/src";
import connect from "../config.crypto";

export default {
    components: {
        NavBar,
        vFooter,
        Toggle,
        SelectLang,
        RequestCityModal
    },

    methods: {
      setUserCity(city_id) {
        this.$refs.navigationBar.setUserCity(city_id);
      },
        async connectWallet() {
            if (this.$store.state.wallet.address == "") {
                const mm = await getAccount({
                    wallet: connect.wallets[0].name,
                    chains: `eip155:${connect.wallets[0].chain[0]}`,
                });
                if (!mm) return false;
                if (mm.accounts && mm.accounts.length != 0) {
                    this.$store.state.wallet.address = mm.accounts[0];
                    this.$store.state.wallet.chainId = mm.chainId;
                    let isSwitch = true;
                    if (!connect.wallets[0].chain.includes(mm.chainId)) {
                        isSwitch = await switchChain({
                            wallet: connect.wallets[0].name,
                            chainId: connect.wallets[0].chain[0],
                            rpc: connect.wallets[0].rpc[0],
                            params: connect.wallets[0].addChainParams[connect.wallets[0].chain[0]],
                        });
                    }
                    if (isSwitch) {
                        this.$store.state.wallet.chainId = isSwitch.chainId;
                    }
                }
            } else {
                this.disconnect();
            }
            this.openConnect = false;
        },

        async requestData(method, bodyItem, func, code) {
            const body = JSON.stringify(bodyItem);
            let dataRequest = undefined;
            if (method == "get") {
                dataRequest = {
                    method: method,
                    headers: {
                        "content-type": "application/json",
                    },
                };
            } else if (method == "post") {
                dataRequest = {
                    method: method,
                    headers: {
                        "content-type": "application/json",
                    },
                    body: body,
                };
            }
            if (dataRequest) {
                let response = await fetch(`${process.env.VUE_APP_SERVER}/api/${func}`, dataRequest);
                if (response.ok) {
                    if (code == "json") {
                        try {
                            return await response.json();
                        } catch {
                            return false;
                        }
                    } else {
                        try {
                            return await response.text();
                        } catch {
                            return false;
                        }
                    }
                } else {
                    console.log("Error HTTP: " + response.status);
                }
            } else {
                console.log("request method not found");
            }
        },

        disconnect() {
            this.$store.state.wallet.address = "";
            this.$store.state.wallet.chainId = 0;
            this.$store.state.wallet.balance = "0";
        },
    },

    watch: {
        $route() {
            const ref = this.$route.query.r;
            if (ref && !localStorage.getItem("ref")) {
                localStorage.setItem("ref", ref);
            }
        },
    },
};
</script>

<style>
*,
::before,
::after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

@font-face {
    font-family: "Inter";
    src: url(./assets/fonts/Inter-VariableFont_slntwght.ttf);
}

html {
    font-size: 16px;
    color: #123123;
}

.light-theme {
    --main-bg-color: #ffffff;
    --sub-bg-color: rgba(255, 255, 255, 0.8);
    --sub-bg-colo-2: rgba(255, 255, 255, 0.8);
    --bg-2: #ffffff;

    --main-color: #000000;
    --sub-color: #ffffff;
    --color-1: #797979;
    --color-2: #7c7c7c;
    --color-3: #b7b7b7;
    --color-4: #9f9f9f;
    --color-5: #9c9c9c;
    --color-6: #939393;
    --color-7: #4a4a4a;
    --color-8: #a7a7a7;
    --color-9: #fafafa;
    --color-10: rgb(37, 49, 76);
    --color-11: #e9e9e9;
    --color-12: #dddddd;
    --color-13: #066feb;
    --color-14: #c1dbfa;
    --footer-border: #6d6d6d;

    --toggle-bg-color: #eeeded;
    --toggle-position: 5px;
    --color-svg: #000000;
}

.dark-theme {
    --main-bg-color: #262626;
    --sub-bg-color: rgba(0, 0, 0, 0.7);
    --sub-bg-color-2: rgba(38, 38, 38, 0.8);
    --bg-2: #3a3a3a;

    --main-color: #ffffff;
    --sub-color: #000000;
    --color-1: #797979;
    --color-2: #7c7c7c;
    --color-3: #666666;
    --color-4: #9f9f9f;
    --color-5: #9c9c9c;
    --color-6: #939393;
    --color-7: #4a4a4a;
    --color-8: #6e6e6e;
    --color-9: #3a3a3a;
    --color-10: rgb(37, 49, 76);
    --color-11: #575757;
    --color-12: #9c9c9c;
    --color-13: #066feb;
    --color-14: #3781d9;
    --footer-border: #6d6d6d;

    --toggle-bg-color: #c9c9c9;
    --toggle-position: calc(100% - 20px);
    --color-svg: #ffffff;
}

#app {
    font-family: "Inter";
    width: 100%;
    min-height: 100vh;
    margin: 0 auto;
}

.app {
    color: var(--main-color);

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    background: var(--main-bg-color);
}

.container {
    padding: 0 4%;
    width: 100%;
    max-width: calc(1680px + 1920px * 0.04 * 2);
    margin: 0 auto;
}

.navBarMain {
    position: fixed;
    z-index: 11;
    top: 0;
    width: 100%;
}

/* text */

.logotext {
    font-weight: 900;
    font-size: 1.5rem;
    line-height: 1.8125rem;

    white-space: nowrap;
    cursor: default;
}

h1 {
    font-weight: 800;
    font-size: 3.5rem;
    line-height: 4.25rem;
}

h2 {
    font-weight: 700;
    font-size: 1.75rem;
    line-height: 112.02%;
}

h3 {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.8125rem;
}

h4 {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.1875rem;
}

h5 {
    font-weight: 800;
    font-size: 1rem;
    line-height: 1.1875rem;
}

.subtitle {
    font-weight: 800;
    font-size: 1.125rem;
    line-height: 1.375rem;
}

.maintext {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.1875rem;
}

.maintext2 {
    font-weight: 500;
    font-size: 1.0625rem;
    line-height: 139.02%;
}

.maintext3 {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 130.52%;
}

.subtext {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 0.9375rem;
    color: var(--color-2);
}

.subtext2 {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.0625rem;
}

/* button and input */

input,
textarea {
    outline: 0;
    font-family: "Inter";
    color: var(--main-color);
}

button {
    font-family: "Inter";
    color: var(--main-color);
}

.mainButton {
    background: rgba(255, 255, 255, 0.4);
    border: 1px solid #ffffff;
    backdrop-filter: blur(5px);
    color: #ffffff;
    cursor: pointer;
    width: 11.75rem;
    height: 3.625rem;
    transition: all 0.2s;
}

.mainButton:hover {
    opacity: 0.75;
}

.mainButton:active {
    opacity: 0.55;
}

.blueButton {
    background: var(--color-13);
    border: 0px solid;
    border-radius: 10px;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.2s;
    position: relative;
}

.blueButton:hover {
    opacity: 0.75 !important;
}

.blueButton:active {
    opacity: 0.55 !important;
}

.blueButton.disactive {
    pointer-events: none;
    opacity: 0.55 !important;
}

.mobileMenu {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(15px);

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

.loaderBg {
    color: rgba(0, 0, 0, 0);
}

.loader {
    border: 3px solid #f3f3f300;
    border-top: 3px solid lightgrey;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.connectBlock {
    border-radius: 0.625rem;
    overflow: hidden;
    background: var(--sub-bg-color);
    padding: 1.125rem 1rem;
    width: max-content;
}

.connectBlock .blueButton {
    width: fit-content;
    padding: 0.5rem 1.375rem;
    border-radius: 10rem;
    margin-top: 1rem;
}

.loadImage {
    background: var(--color-9);
}

.v-enter-active,
.v-leave-active {
    transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}


.city-select {
  background-color: var(--sub-bg-color);
  padding: 8px 16px;
  border-radius: 10px;
  box-sizing: border-box;
  white-space: nowrap;
  height: 2.875rem;
  display: flex;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  gap: 10px;
  transition: all 0.2s;
}

.city-select:hover {
  opacity: .8;
}

.city-select i {
  font-size: 18px;
}

@media screen and (max-width: 1200px) {
    html {
        font-size: 14px;
    }
}

@media screen and (max-width: 800px) {
    h1 {
        font-size: 3rem;
        line-height: 3.6rem;
    }
}

@media screen and (max-width: 600px) {
    h1 {
        font-size: 2.5rem;
        line-height: 3.2rem;
    }
}

@media screen and (max-width: 550px) {
    .logotext {
        font-size: 1.2rem;
        line-height: 1.5rem;
    }
}
</style>
