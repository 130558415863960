<template>
  <div v-if="collections && collections.length > 0">
    <div class="container hotTitle">
      <h3>{{ this.$store.state.translation[this.$store.state.lang].rentcollection.title }}</h3>
    </div>

    <div class="container">
      <CollectionCard
          v-for="item of collections"
          :key="item.id"
          :icon="item.image?.image"
          :price="item.price"
          :title="item.name"
          :chainId="item.chain_id"
          :address="item.address"
          :blockchainId="item.blockchain_id"
      />
    </div>
  </div>
</template>

<script>
import CollectionCard from "./CollectionCard.vue";
import { callViewContractFunction } from "kasatkin-crypto-core/src";
import connect from "../../config.crypto";
import RentCollectionABI from "../ABI/RentCollectionABI.json";

export default {
    components: {
        CollectionCard,
    },

    data() {
        return {
            collections: [],
        };
    },

    methods: {
        async getCryptoData(address, abi, cryptoFunc, args, rpc) {
            let cryptoData = await callViewContractFunction({
                from: this.$store.state.wallet.address,
                to: address,
                contractabi: abi,
                function: cryptoFunc,
                args: args,
                rpc: rpc,
            });
            return cryptoData;
        },

        async loadData() {
            const rentCollections = await this.requestData("get", {}, "collection/rent", "json");
            this.collections = rentCollections;
            for (let collection of this.collections) {
                const metaInfo = await this.getCryptoData(
                    collection.address,
                    RentCollectionABI,
                    "checkPrice",
                    [],
                    connect.wallets[0].rpc[connect.wallets[0].chain[0]]
                );
                collection.price = metaInfo;
            }
        },

        async requestData(method, bodyItem, func, code) {
            const body = JSON.stringify(bodyItem);
            let dataRequest = undefined;
            if (method == "get") {
                dataRequest = {
                    method: method,
                    headers: {
                        "content-type": "application/json",
                    },
                };
            } else if (method == "post") {
                dataRequest = {
                    method: method,
                    headers: {
                        "content-type": "application/json",
                    },
                    body: body,
                };
            }
            if (dataRequest) {
                let response = await fetch(`${process.env.VUE_APP_SERVER}/api/${func}`, dataRequest);
                if (response.ok) {
                    if (code == "json") {
                        try {
                            return await response.json();
                        } catch {
                            return false;
                        }
                    } else {
                        try {
                            return await response.text();
                        } catch {
                            return false;
                        }
                    }
                } else {
                    console.log("Error HTTP: " + response.status);
                }
            } else {
                console.log("request method not found");
            }
        },

        async getMetaData(i) {
            let tokenURL = "";
            try {
                tokenURL = "";
                tokenURL = tokenURL.split("/");
                tokenURL[tokenURL.length - 1] = tokenURL[tokenURL.length - 1].split(".");
                tokenURL[tokenURL.length - 1][0] = this.nfts[i].id;
                tokenURL[tokenURL.length - 1] = tokenURL[tokenURL.length - 1].join(".");
                tokenURL = tokenURL.join("/");
                let imageURL = await fetch(tokenURL.replace("ipfs://", "https://nftstorage.link/ipfs/"));
                imageURL = await imageURL.json();
                this.nfts[i].title = imageURL.name;
                if (this.nfts[i].icon != imageURL.image.replace("ipfs://", "https://nftstorage.link/ipfs/")) {
                    this.nfts[i].icon = imageURL.image.replace("ipfs://", "https://nftstorage.link/ipfs/");
                }
            } catch {
                return false;
            }
        },

        transEvent(event) {
            if (event.detail.txHash) {
                if (event.detail.status == "succes") {
                    this.loadData();
                }
            }
        },
    },

    mounted() {
        this.loadData();
        addEventListener("SendTransaction", this.transEvent, true);
    },

    beforeUnmount() {
        removeEventListener("SendTransaction", this.transEvent, true);
    },
};
</script>

<style scoped>
.container {
    margin-bottom: 6rem;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
}

.hotTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 4rem auto 2.1875rem auto;
  text-transform: uppercase;
}

.hotTitle {
  margin: 4rem auto 1rem auto;
}

.item {
    width: 100%;
}

@media screen and (max-width: 1540px) {
    .container {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media screen and (max-width: 1100px) {
    .container {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width: 800px) {
    .container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 520px) {
    .container {
        grid-template-columns: repeat(1, 1fr);
    }
}
</style>
