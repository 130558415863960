import { createStore } from "vuex";

export default createStore({
    state: {
        defaultTitle: "Place Of Good",
        lang: "ru",
        theme: false,
        signingMessage: {
            welcome: "Welcome to the PlaceOfGood!",
        },
        translation: {
            ru: {
                alerts: {
                    connectWallet: "Подключите кошелек",
                    withdrawn: "Успешно выведено\nХэш: ",
                    someError: "Неизвестная ошибка",
                    minWithdrawalAmount: "Минимальная сумма вывода составляет: ",
                    wrongSignature: "Неверная подпись",
                    yourCityQuestion: "Ваш город ",
                    selectOther: 'Выбрать другой',
                    right: 'Верно',
                    selectCity: 'Выберите город',
                    inputCityName: 'Введите название города',
                    tags: "Тэги",
                    search: "Поиск",
                    cancel: "Отмена",
                    city: "Город"
                },
                common: {
                    profile: "Профиль",
                    settings: "Настройки",
                    logOut: "Выйти",
                    connect: "Подключиться",
                    connectTo: "Подключить Metamask",
                    collection: "Коллекции",
                    more: "Подробнее",
                    selling: "На продаже",
                    owner: "Владелец",
                    created: "Создатель",
                    volume: "Объем",
                    count: "Кол-во",
                    currentPrice: "Цена",
                    description: "Описание",
                    view: "Подробнее",
                    name: "Имя",
                    withdraw: "Вывод",
                    withdrawAll: "Вывести все",
                    total: "Всего",
                    chain: "Сеть",
                    disconnect: "Отключиться",
                    placeholderCost: "Цена",
                    notSale: "Не продается",
                    sell: "Продать",
                    buyNow: "КУПИТЬ",
                    mint: "МИНТ",
                    newInviteSpots: ["Вы получите", "приглашений."],
                    changePrice: "ИЗМЕНИТЬ ЦЕНУ",
                    cancelListing: "СНЯТЬ С ПРОДАЖИ",
                    copy: "Скопировать",
                    successMint: "Успешно сминьчено",
                    registerFirst: "Сначала зарегистрируйтесь",
                    insufficientFunds: "Недостаточно средств",
                    someError: "Неизвестная ошибка",
                    howBuy: 'Как купить?',
                    howEarn: 'Как заработать?'
                },
                searchPlaceholder: "Поиск NFT, пользователей",
                search: {
                    collection: "Коллекции",
                    profile: "Пользователи",
                },
                firstScrin: {
                    title: "Цифровое искусство",
                    titleSecond: 'для благотворительности',
                    text: "Новый подход в благотворительности для сбора средств нуждающимся. Честный и прозрачный заработок для каждого.",
                    subVideo: 'Узнайте из видео, как работает наш сервис:',
                    linkFirst: 'Для благотворительных организаций',
                    linkSecond: 'Для блогеров и лидеров Мнений',
                    linkThree: 'Как зарабатывать?',
                    linkFour: 'Инструкция по регистрации',
                    button: "Подробнее",
                },
                slider: {
                    collection: "Коллекции",
                    button: "Подробнее",
                },
                topusers: {
                    title: "Топ пользователей",
                    username: "Имя пользователя",
                    volume: "Объем",
                    iconText: 'В топе пользователей'
                },
                hotaccounts: {
                    title: "Благотворительные организации",
                    collection: "Коллекция",
                    count: "Кол-во",
                    volume: "Объем",
                },
                lastaccounts: {
                  title: 'Новые Благотворительные организации',
                },
                rentcollection: {
                    title: "АРЕНДНЫЕ КОЛЛЕКЦИИ",
                    collection: "Коллекции",
                    floor: "Минимальная цена",
                    value: "Оборот",
                },
                footer: {
                    community: "Присоединяйтесь к сообществу",
                    rights: "2023  Все права защищены",
                },
                topcollection: {
                    title: "ЛУЧШИЕ КОЛЛЕКЦИИ",
                    price: "Цена",
                },
                feecollection: {
                  title: 'СРОЧНЫЕ СБОРЫ',
                  price: 'Цена',
                  to: 'Срочный сбор до'
                },
                urgentlyCollection: {
                    title: "СРОЧНО НУЖНА ПОМОЩЬ",
                    price: "Цена",
                },
                item: {
                    description: "Описание",
                },
                profile: {
                    profile: "Профиль",
                    refSystem: "Реферальная система",
                    certificates: "Сертификаты",
                    verified: "Документы проверены",
                    shelters: 'Приюты',
                    volunteer: 'Волонтеры',
                    inputs: {
                        username: {
                            title: "Имя пользователя",
                            placeholder: "Введите имя пользователя",
                        },
                        bio: {
                            title: "О вас",
                            placeholder: "Расскажите о себе",
                        },
                        email: {
                            title: "Адрес электронной почты",
                            placeholder: "Введите свой email",
                        },
                        phone: {
                            title: "Номер телефона",
                            placeholder: "Введите свой номер телефона",
                        },
                        social: {
                            title: "Социальные сети",
                            placeholder: "Ссылка",
                        },
                        address: "Адрес кошелька",
                        image: {
                            title: "Фото профиля",
                            info: "Рекомендуем: 230x230 Максимальный размер: 3MB",
                        },
                        banner: {
                            title: "Баннер профиля",
                            info: "Рекомендуем: 1920x840 Максимальный размер: 10MB",
                        },
                    },
                    unlimitedCollections: "Коллекции",
                    rentCollections: "Арендные коллекции",
                    save: "Сохранить",
                    countCustomer: "Количество покупателей",
                    eachNft: "Каждый NFT",
                    bought: "Покупок",
                    earned: "Всего заработано",
                    refSystemInfo:
                        "Если кто-то купит NFT, которую вы купили ранее, то вы можете получить вознаграждение за привлечение этого покупателя. Это значит, что если вы поделились своей ссылкой на NFT с другими людьми и они тоже купили этот NFT, вы получите бонус в награду за привлечение новых покупателей.",
                    totalEarned: "Всего заработано",
                    withdrawn: "Выведено средств",
                    available: "Доступно для вывода",
                    yourLink: "Ваша реферальная ссылка:",
                    availableSpots: "Доступно приглашений:",
                    statByLevels: {
                        header: "Статистика по реферальным уровням",
                        count: "Кол-во",
                        earned: "Заработано",
                    },
                    statOfFirstLevel: {
                        header: "Ваши рефералы (1 уровень)",
                        user: "Пользователь",
                        bought: "Куплено NFT",
                        invited: "Приглашено",
                        earned: "Заработано",
                    },
                    statLastPurchases: {
                        header: "Последние 10 покупок рефералов",
                        time: "Время",
                        address: "Адрес",
                        nftId: "NFT Id",
                        collection: "Коллекция",
                        earned: "Заработано",
                        deleted: "Удалено",
                    },
                },
                create: {
                    title: "СОЗДАТЬ НОВУЮ КОЛЛЕКЦИЮ",
                    bannerimage: "Баннер",
                    collectionimage: "Картинка",
                    recommended: "Рекомендуем",
                    maxsize: "Максимальный размер",
                    create: "Создать коллекцию",

                    inputs: {
                        externallink: {
                            title: "URI ссылка",
                            placeholder: "URI ссылка на метаданные",
                        },

                        description: {
                            title: "Описание",
                            placeholder: "Расскажите о своей коллекции",
                        },

                        collection: {
                            title: "Коллекция",
                            placeholder1: "Название",
                            placeholder2: "Краткое название",
                        },

                        maxsupply: {
                            title: "Всего HFT",
                            placeholder: "Введите количество NFT",
                        },

                        blockchain: {
                            title: "Блокчейн",
                            placeholder: "",
                        },

                        resalecommission: "Комиссия при перепродаже (0%-27%)",

                        creatorcommission: "Комиссия создателя (0%-50%)",

                        artistscommission: "Комиссия художника (0%-3%)",

                        artistscommissionaddress: "Адрес художника",

                        // : {
                        //   title: '',
                        //   placeholder: ''
                        // },

                        // : {
                        //   title: '',
                        //   placeholder: ''
                        // },
                    },

                    walletIsNot: "Кошелек не подключен",
                    balanceIsNot: "Недостаточно баланса",
                    requiredFields: "Не заполнены обязательные поля",
                },
                addOrganization: {
                    title: 'Заявка',
                    subtitle: ['На добавление частного лица/организации', 'действующих в благотворительных целях'],
                    name: 'Название',
                    namePlaceholder: 'Введите название',
                    address: 'Страна/Город/Адрес',
                    defaultPlaceholder: 'Введите значение',
                    description: 'Опишите то чем вы занимаетесь',
                    site: 'Сайт',
                    vkontakte: 'ВКонтакте',
                    yandex: 'Яндекс Дзен',
                    save: 'Сохранить',
                    button: 'Добавление благотворительной организации',
                    errorData: 'Заполните все обязательные поля',
                    errorResponse: 'При заявке произошла ошибка',
                    success: 'Заявка успешно добавлена'
                }
            },
            en: {
                alerts: {
                    connectWallet: "Connect wallet",
                    withdrawn: "Successfully withdrawn\nHash: ",
                    someError: "Some error",
                    minWithdrawalAmount: "Min withdrawal amount is: ",
                    wrongSignature: "Wrong signature",
                    yourCityQuestion: "your city is ",
                    selectOther: 'Choose another',
                    right: 'Right',
                    selectCity: 'Select a city',
                    inputCityName: 'Enter the name of the city',
                    tags: "Tags",
                    search: "Search",
                    cancel: "Cancel",
                    city: "City"
                },
                common: {
                    profile: "Profile",
                    settings: "Settings",
                    logOut: "Log out",
                    connect: "Connect",
                    connectTo: "Connect to MetaMask",
                    collection: "collection",
                    more: "More",
                    selling: "Selling",
                    owner: "Owner",
                    created: "Created",
                    volume: "Volume",
                    count: "Count",
                    currentPrice: "Current price",
                    description: "Description",
                    view: "View",
                    name: "Name",
                    withdraw: "Withdraw",
                    withdrawAll: "Withdraw all",
                    total: "Total",
                    chain: "Chain",
                    disconnect: "Disconnect",
                    placeholderCost: "Cost",
                    notSale: "Not in sale",
                    sell: "SELL",
                    buyNow: "BUY NOW",
                    mint: "MINT",
                    newInviteSpots: ["You will get", "invite spots after purchase."],
                    changePrice: "CHANGE PRICE",
                    cancelListing: "CANCEL LISTING",
                    copy: "Copy",
                    successMint: "Successfully minted",
                    registerFirst: "Register first",
                    insufficientFunds: "Insufficient funds",
                    someError: "Some error",
                    howBuy: 'How to buy?',
                    howEarn: 'How to earn?'
                },
                searchPlaceholder: "Search items, images and profiles",
                search: {
                    collection: "Collection",
                    profile: "Profile",
                },
                firstScrin: {
                    title: "Digital art for charity",
                    text: "A new approach to charity for raising funds for those in need. Honest and transparent earnings for everyone",
                    subVideo: 'Find out from the video',
                    linkFirst: 'For charitable organizations',
                    linkSecond: 'For bloggers and opinion leaders',
                    linkThree: 'How to earn money?',
                    linkFour: 'Registration instructions',
                    button: "Explore",
                },
                slider: {
                    collection: "collection",
                    button: "View",
                },
                topusers: {
                    title: "Top users",
                    username: "Username",
                    volume: "Volume",
                    iconText: 'Number in the top users'
                },
                hotaccounts: {
                    title: "Charity organisations",
                    collection: "Collection",
                    count: "Count",
                    volume: "Volume",
                },
                lastaccounts: {
                    title: 'New Charitable Organizations',
                },
                rentcollection: {
                    title: "RENT COLLECTION",
                    collection: "Collection",
                    floor: "Floor price",
                    value: "Volume",
                },
                footer: {
                    community: "Join the community",
                    rights: "2023  All rights reserved",
                },
                topcollection: {
                    title: "HOT COLLECTIONS",
                    price: "Price",
                },
                feecollection: {
                    title: 'URGENT FEES',
                    price: 'Price',
                    to: 'Urgent collection until'
                },
                urgentlyCollection: {
                    title: "HELP URGENTLY NEEDED",
                    price: "Price",
                },
                item: {
                    description: "Description",
                },
                profile: {
                    profile: "Profile",
                    refSystem: "Referral System",
                    certificates: "Certificates",
                    verified: "Documents verified",
                    shelters: 'Shelters',
                    volunteer: 'Volunteer',
                    inputs: {
                        username: {
                            title: "Username",
                            placeholder: "Enter username",
                        },
                        bio: {
                            title: "Bio",
                            placeholder: "Tell something interesting ",
                        },
                        email: {
                            title: "Email Address",
                            placeholder: "Enter your email",
                        },
                        phone: {
                            title: "Phone number",
                            placeholder: "Enter your phone number",
                        },
                        social: {
                            title: "Social Connections",
                            placeholder: "Link",
                        },
                        address: "Wallet Address",
                        image: {
                            title: "Profile Image",
                            info: "Recommended: 230x230 Max size: 3MB",
                        },
                        banner: {
                            title: "Profile Banner",
                            info: "Recommended: 1920x840 Max size: 10MB",
                        },
                    },
                    unlimitedCollections: "Collections",
                    rentCollections: "Rent collections",
                    save: "Save",
                    countCustomer: "Count of Customers",
                    eachNft: "Each NFT",
                    bought: "Bought",
                    earned: "Total earned",
                    refSystemInfo:
                        "If someone buys the same NFT that you bought earlier, then you can get a reward for attracting this buyer. This means that if you shared your link to NFT with other people and they also bought this NFT, you can get a bonus as a reward for attracting new customers.",
                    totalEarned: "Total Earned From All Time",
                    withdrawn: "Withdrawn",
                    available: "Available",
                    yourLink: "Your referral link:",
                    availableSpots: "Available invite spots: ",
                    statByLevels: {
                        header: "Statistic by referral levels",
                        count: "Count",
                        earned: "Earned",
                    },
                    statOfFirstLevel: {
                        header: "Your referrals (1 level)",
                        user: "User",
                        bought: "Bought NFT",
                        invited: "Invited",
                        earned: "Total earned",
                    },
                    statLastPurchases: {
                        header: "Last 10 referral’s purchases",
                        time: "Time",
                        address: "Address",
                        nftId: "NFT Id",
                        collection: "Collection",
                        earned: "Earned",
                        deleted: "Deleted",
                    },
                },
                create: {
                    title: "CREATE A NEW COLLECTION",
                    onlyimage: "Only image",
                    recommended: "Recommended",
                    maxsize: "Max size",
                    create: "Create collection",

                    inputs: {
                        externallink: {
                            title: "URI link",
                            placeholder: "Metadata link",
                        },

                        description: {
                            title: "Description",
                            placeholder: "Tell about your item",
                        },

                        collection: {
                            title: "Collection",
                            placeholder1: "Name",
                            placeholder2: "Short Name",
                        },

                        maxsupply: {
                            title: "Max supply",
                            placeholder: "NFT amount",
                        },

                        blockchain: {
                            title: "Blockchain",
                            placeholder: "",
                        },

                        resalecommission: "Resale commission (0%-27%)",

                        creatorcommission: "Creator commission (0%-50%)",

                        artistscommission: "Artist's commission (0%-3%)",

                        artistscommissionaddress: "Artist's address",
                    },

                    walletIsNot: "Wallet is not connected",
                    balanceIsNot: "Insufficient balance",
                    requiredFields: "Required fields are not filled in",
                },
                addOrganization: {
                    title: 'Application',
                    subtitle: ['To add an individual/organization', 'operating for charitable purposes'],
                    name: 'Name',
                    namePlaceholder: 'Enter the title',
                    address: 'Country/City/Address',
                    defaultPlaceholder: 'Enter value',
                    description: 'Describe what you do',
                    site: 'Website',
                    vkontakte: 'Vkontakte',
                    yandex: 'Yandex Zen',
                    save: 'Save',
                    button: 'Adding a charity',
                    errorData: 'Fill in all required fields',
                    errorResponse: 'There was an error with your application',
                    success: 'Application added successfully'
                }
            },
        },

        navActive: false,
        navActiveCen: false,
        navActiveBgCen: false,

        wallet: {
            address: "",
            chainId: 0,
            balance: "",
        },
    },
    mutations: {
        setLang(state, lang) {
            state.lang = lang;
        },
        setTheme(state, theme) {
            state.theme = theme;
        },
        setNavActive(state, navActive) {
            state.navActive = navActive;
        },
        setNavActiveCen(state, navActiveCen) {
            state.navActiveCen = navActiveCen;
        },
        setNavActiveBgCen(state, navActiveBgCen) {
            state.navActiveBgCen = navActiveBgCen;
        },
    },
    actions: {
        setLang({ commit }, lang) {
            commit("setLang", lang);
        },
        setTheme({ commit }, theme) {
            commit("setTheme", theme);
        },
        setNavActive({ commit }, navActive) {
            commit("setNavActive", navActive);
        },
        setNavActiveCen({ commit }, navActiveCen) {
            commit("setNavActiveCen", navActiveCen);
        },
        setNavActiveBgCen({ commit }, navActiveBgCen) {
            commit("setNavActiveBgCen", navActiveBgCen);
        },
    },
});
