<script>
import axios from "axios";

export default {
  data() {
    return {
      tagList: null,
      selectedTags: [],
      show: false,
    }
  },
  async created() {
    this.getTagList();
  },
  methods: {
    open(show = !this.show) {
      this.show = show;
    },
    getTagList() {
      axios.get(`${process.env.VUE_APP_SERVER}/api/tag/list`).then(({ data }) => {
        this.tagList = data;
      }).catch(error => {
        console.log(error);
      });
    },
  }
}
</script>

<template>
  <div class="filter-search" v-if="show">
    <button class="close" @click="show = false;">
      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 26 26">
        <path d="M 21.734375 19.640625 L 19.636719 21.734375 C 19.253906 22.121094 18.628906 22.121094 18.242188 21.734375 L 13 16.496094 L 7.761719 21.734375 C 7.375 22.121094 6.746094 22.121094 6.363281 21.734375 L 4.265625 19.640625 C 3.878906 19.253906 3.878906 18.628906 4.265625 18.242188 L 9.503906 13 L 4.265625 7.761719 C 3.882813 7.371094 3.882813 6.742188 4.265625 6.363281 L 6.363281 4.265625 C 6.746094 3.878906 7.375 3.878906 7.761719 4.265625 L 13 9.507813 L 18.242188 4.265625 C 18.628906 3.878906 19.257813 3.878906 19.636719 4.265625 L 21.734375 6.359375 C 22.121094 6.746094 22.121094 7.375 21.738281 7.761719 L 16.496094 13 L 21.734375 18.242188 C 22.121094 18.628906 22.121094 19.253906 21.734375 19.640625 Z"></path>
      </svg>
    </button>

    <label style="display: block; margin-top: 20px;" for="tags">{{ this.$store.state.translation[this.$store.state.lang].alerts.tags }}</label>
    <div class="filter-search__tags-list">
      <button class="filter-search__tags-item"
              v-for="tag in tagList" :key="tag.id"
              @click="selectedTags.includes(tag.id) ? selectedTags.splice(selectedTags.indexOf(tag.id), 1) : selectedTags.push(tag.id);"
              :class="{ active: selectedTags.includes(tag.id) }">
        {{ tag.name }}
      </button>
    </div>

    <div class="filter-search__buttons">
      <button class="blueButton" @click="show = false; $emit('search')">{{ this.$store.state.translation[this.$store.state.lang].alerts.search }}</button>
      <button class="other" @click="show = false; this.selectedTags = []; this.selectedCity = null">
        {{ this.$store.state.translation[this.$store.state.lang].alerts.cancel }}
      </button>
    </div>
  </div>
</template>

<style scoped>
  .filter-search {
    position: absolute;
    border-radius: 10px;
    width: 600px;
    min-height: 100px;
    background: var(--sub-bg-color);
    backdrop-filter: blur(2px);
    z-index: 100;
    left: 50%;
    top: 90px;
    font-size: 16px;
    padding: 10px 15px;
    transform: translate(-50%);
    box-shadow: 10px 0 10px rgba(0, 0, 0, 0.1);
  }

  .filter-search__tags-list {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 10px;
  }

  .filter-search__tags-item {
    padding: 5px 7px;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.1);
    font-size: 16px;
    cursor: pointer;
    transition: ease-in-out 0.2s;
    border: none;
    outline: none;
  }

  .filter-search__tags-item:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }

  .filter-search__tags-item.active {
    background-color: var(--color-13);
    color: #ffffff;
  }

  .filter-search input {
    border: 1px solid var(--sub-bg-color-2);
    border-radius: 5px;
    font-size: 16px;
    background: var(--sub-bg-color-2);
    outline: none;
    padding: 5px 10px;
    display: block;
    margin: 10px 0;
  }

  .filter-search .close {
    background: transparent;
    border: none;
    outline: none;
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 16px;
    cursor: pointer;
    transition: ease-in-out 0.2s;
  }

  .filter-search .close:hover svg {
    opacity: 0.7;
  }

  .filter-search .close svg {
    fill: var(--main-color);
    width: 15px;
    height: 15px;
  }

  .filter-search__buttons {
    margin-top: 20px;
    display: flex;
    gap: 10px;
  }

  .filter-search__buttons .blueButton {
    padding: 7px 15px;
    min-width: 120px;
    font-size: 16px;
  }

  .filter-search__buttons .other {
    background: none;
    outline: none;
    border: none;
    font-size: 14px;
    cursor: pointer;
    text-decoration: underline;
    transition: ease-in-out 0.2s;
  }

  .filter-search__buttons .other:hover {
    opacity: 0.8;
  }

  @media (max-width: 650px) {
    .filter-search {
      width: 400px;
    }
  }

  @media (max-width: 450px) {
    .filter-search {
      width: 90%;
      max-height: 80vh;
      overflow-y: scroll;
    }
  }
</style>