module.exports = {
    wallets: [
        {
            name: "MetaMask",
            chain: [137,  /*80001*/],
            chainName: {
                137: "Polygon",
                80001: "Polygon",
            },
            rpc: {
                137: "https://rpc.ankr.com/polygon/5fb90d2b5f1f6658c05640962a47e6d949abe3616a42ef00b0bbeda04d36c71b",
                80001: "https://polygon-mumbai.infura.io/v3/cc39de61205c42b3a34e22a1848ba7eb",
            },
            rpcWSS: {
                137: "wss://rpc.ankr.com/polygon/ws/5fb90d2b5f1f6658c05640962a47e6d949abe3616a42ef00b0bbeda04d36c71b",
                80001: "wss://polygon-mumbai.infura.io/ws/v3/cc39de61205c42b3a34e22a1848ba7eb",
            },
            decimals: {
                137: 18,
                80001: 18,
            },
            tokenName: {
                137: "MATIC",
                80001: "MATIC",
            },
            addChainParams: {
                137: {
                    chainId: "0x89",
                    chainName: "Polygon",
                    nativeCurrency: {
                        name: "MATIC",
                        symbol: "MATIC",
                        decimals: 18,
                    },
                    rpcUrls: ["https://polygon-rpc.com"],
                },
                80001: {
                    chainId: "0x13881",
                    chainName: "Mumbai",
                    nativeCurrency: {
                        name: "MATIC",
                        symbol: "MATIC",
                        decimals: 18,
                    },
                    rpcUrls: ["https://rpc.ankr.com/polygon_mumbai"],
                },
            },
        },
    ],
    blockchainScanLinks: {
        137: "https://polygonscan.com",
        80001: "https://mumbai.polygonscan.com",
    },
};
