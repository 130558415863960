<template>
    <div class="item" v-on:click="toItem">
        <div class="itemImg" v-bind:class="{ loadImage: item.collection?.image?.image == (null && undefined && '') }">
            <img :src="item.collection?.image?.image" v-if="item.collection?.image?.image != (null && undefined && '')" />
        </div>
        <div class="itemText">
            <h5 v-if="item.collection?.name != '' && item.collection?.name != undefined">
                {{ item.collection?.name.length <= 25 ? item.collection?.name : item.collection?.name.slice(0, 25) + ".." }}
            </h5>
            <div v-if="owner" class="subtext owner">{{ owner }}</div>
            <div class="fee">
              <div class="subname">{{ item.name }}</div>
              <div class="subname small">
                {{ item.sell_value }} matic ({{ sellUsdPrice }} USD) /
                {{ item.value }} matic ({{ usdPrice }} USD)
              </div>
              <div class="fee-bar">
                <span>{{ progress }}%</span>
                <div class="fee-bar-inner" :style="`width: ${progress}%`"></div>
              </div>
            </div>
            <div class="subtext">{{ this.$store.state.translation[this.$store.state.lang].feecollection.price }}</div>
            <div class="price">
                <img src="../assets/matic.png" />
                <div class="subtext2">
                    {{
                            item.collection?.price == "" || item.collection?.price == undefined
                            ? "-"
                            : Math.round(item.collection?.price / 10 ** (Number(decimals[item.collection?.chain_id]) - 6)) / 10 ** 6
                    }}
                    {{ tokenName[item.collection?.chain_id] }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import connect from "../../config.crypto";
import {ajaxBinance} from "kasatkin-crypto-core/src";

export default {
    props: ['item'],

    data() {
        return {
            decimals: connect.wallets[0].decimals,
            tokenName: connect.wallets[0].tokenName,
            usdPrice: 0,
            sellUsdPrice: 0,
            progress: 0
        };
    },
    async created() {
      this.usdPrice = await this.ethToUsd(this.item.value);
      this.sellUsdPrice = await this.ethToUsd(this.item.sell_value);
      this.progress = Math.round(this.item.sell_value / this.item.value * 100);
    },

  methods: {
        toItem() {
            this.$router.push(`/collection/${this.item.collection?.chain_id}/${this.item.collection?.address}/${this.item.collection?.blockchain_id}`);
        },
        async ethToUsd(matic) {
          try {
            const price = await ajaxBinance({
              tokenName1: connect.wallets[0].tokenName[this.item.collection?.chain_id],
              tokenName2: "USDT",
            });


            if (!price || price == 0) {
              console.log("api error");
              return 0;
            }
            let subPriceUsd = Number(price) * matic;
            if (subPriceUsd < 100) {
              return Math.round(subPriceUsd * 100) / 100;
            } else {
              return Math.round(subPriceUsd);
            }
          } catch {
            console.log("api error");
          }

          return 0;
        },
    },
};
</script>

<style scoped>
.item {
    width: 100%;
    background: var(--bg-2);

    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.06);
    border-radius: 1.25rem;
    cursor: pointer;
}

.item:hover .itemImg img {
    transform: scale(1.15);
}

.itemImg {
    border-radius: 1.25rem;
    height: 13.3rem;
    width: 100%;
    overflow: hidden;
}

.itemImg img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: all 0.3s;
}

.itemText {
    padding: 0.5rem 1.125rem 1.125rem 1.125rem;
}

.fee {
  margin: 15px 0;
}

.fee-bar {
  width: 100%;
  height: 15px;
  background: var(--color-4);
  margin-top: 5px;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
}

.fee-bar-inner {
  width: 0%;
  height: 100%;
  background: var(--color-13);
  border-radius: 15px;
}

.fee-bar span {
  position: absolute;
  color: var(--main-color);
  font-weight: 600;
  font-size: 12px;
  padding-top: 1px;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 999999;
}

.subname {
  color: var(--color-2);
}

.subname.small {
  font-size: 14px;
}

.subtext {
    margin: 1.125rem 0 0.5625rem 0;
}

.subtext.owner {
    margin: 0.1rem 0 0;
}

.price {
    display: flex;
    align-items: center;
    gap: 0.25rem;
}

.price img {
    height: 1rem;
}

.price .subtext2 {
    font-weight: 700;
}
</style>
