<template>
    <div class="item" v-on:click="toItem" :style="`background-color: ${background || 'var(--bg-2)'}`">
        <div class="itemImg" v-bind:class="{ loadImage: icon == (null && undefined && '') }">
            <img :src="icon" v-if="icon != (null && undefined && '')" />
        </div>
        <div class="itemText">
            <h5 v-if="title != '' && title != undefined">
                {{ title.length <= 25 ? title : title.slice(0, 25) + ".." }}
            </h5>
            <div v-if="owner" class="subtext owner">{{ owner }}</div>
            <div class="subtext">{{ this.$store.state.translation[this.$store.state.lang].topcollection.price }}</div>
            <div class="price">
                <img src="../assets/matic.png" />
                <div class="subtext2">
                    {{
                        price == "" || price == undefined
                            ? "-"
                            : Math.round(price / 10 ** (Number(decimals[chainId]) - 6)) / 10 ** 6
                    }}
                    {{ tokenName[chainId] }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import connect from "../../config.crypto";

export default {
    props: {
        background: String,
        title: String,
        owner: String,
        price: String,
        icon: String,
        chainId: Number,
        address: String,
        blockchainId: Number,
    },

    data() {
        return {
            decimals: connect.wallets[0].decimals,
            tokenName: connect.wallets[0].tokenName,
        };
    },

    methods: {
        toItem() {
            this.$router.push(`/collection/${this.chainId}/${this.address}/${this.blockchainId}`);
        },
    },
};
</script>

<style scoped>
.item {
    width: 100%;
    background: var(--bg-2);

    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.06);
    border-radius: 1.25rem;
    cursor: pointer;
}

.item:hover .itemImg img {
    transform: scale(1.15);
}

.itemImg {
    border-radius: 1.25rem;
    height: 13.3rem;
    width: 100%;
    overflow: hidden;
}

.itemImg img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: all 0.3s;
}

.itemText {
    padding: 0.5rem 1.125rem 1.125rem 1.125rem;
}

.subtext {
    margin: 1.125rem 0 0.5625rem 0;
}

.subtext.owner {
    margin: 0.1rem 0 0;
}

.price {
    display: flex;
    align-items: center;
    gap: 0.25rem;
}

.price img {
    height: 1rem;
}

.price .subtext2 {
    font-weight: 700;
}
</style>
