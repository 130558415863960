<script>
import html2canvas from 'html2canvas';

export default {
  name: 'CertificateList',
  props: ['certificates'],
  data() {
    return {
      certificateUrl: process.env.VUE_APP_SERVER,
      images: []
    }
  },
  created() {
    this.$nextTick(() => {
      setTimeout(() => {
        console.log(this.$refs)
        this.$refs.certificate?.forEach(element => {
          html2canvas(element.firstChild).then(canvas => {
            console.log(canvas.toDataURL('image/png'))
            this.images.push(canvas.toDataURL('image/png'));
          });
        })
      }, 1500);
    })
  },
  methods: {
    getDateString(certificate) {
      const createdAt = new Date(certificate.createdAt);
      let date, month, year;
      date = createdAt.getDate();
      month = createdAt.getMonth() + 1;
      year = createdAt.getFullYear();
      date = date.toString().padStart(2, '0');
      month = month.toString().padStart(2);
      return date + '.' + month + '.' + year;
    },
    getColor(certificate) {
      let color = 'blue';
      if (certificate.id % 3 === 0) {
        color = 'orange';
      } else if (certificate.id % 4 === 0) {
        color = 'yellow';
      } else if (certificate.id % 5 === 0) {
        color = 'green';
      } else if (certificate.id % 2 === 0) {
        color = 'violet';
      }

      return color;
    },
  }
}
</script>

<template>
  <div>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Caveat:wght@700&family=Inter:wght@400;500;600;700;800&display=swap" rel="stylesheet">

    <div class="certificates__item"
         @click="$emit('open', image)"
         v-for="(image, index) in images"
         :key="index">
      <img :src="image" />
    </div>

    <div ref="certificate"
         v-for="certificate in certificates"
         style="position: absolute; left: -2000px; top: -2000px; z-index: -999"
         :key="certificate.id">
      <div class="certificates__item-image" :class="getColor(certificate)">
        <div class="water-text">
          <span>МЕСТО ДОБРА — PlaceOfGood.com</span>
          <span>{{ getDateString(certificate) }}</span>
        </div>
        <div class="container">
          <div class="left">
            <h1>СЕРТИФИКАТ<br> БЛАГОТВОРИТЕЛЯ</h1>
            <div class="content">
              <div class="content__name">
                {{ certificate?.user?.username || '-' }}
              </div>
              <span>пожертвовал</span>
              <div class="content__matic">
                {{ certificate.value }} Matic ({{ Math.round(certificate.price) }}$)
              </div>
              <span>приюту "{{ certificate?.collection?.name || '-' }}"</span>
              <div class="line"></div>
              <span>Всего пожертвований </span>
              <div class="content__all-matic">
                {{ certificate.total_value }} Matic ({{ Math.round(certificate.total_price) }}$)
              </div>
            </div>
          </div>
          <div class="right">
            <div class="content__right-bar">
              <div class="circle">
                <div class="inner-circle">
                  <div class="avatar">
                    <img :src="certificate?.collection?.owner?.profile_image?.image">
                  </div>
                </div>
              </div>
              <div class="text">
                Личная благодарность<br>
                <span>от приюта “{{ certificate?.collection?.name || '-' }}”</span>
              </div>

              <div class="description">
                Вы делаете добрые дела.<br>
                Благодарим Вас<br>
                за Ваше неравнодушие<br>
                к чужим проблемам.<br>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>

.certificates__item-image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -999;
}

.certificates__item-image.blue {
  background: linear-gradient(180deg, #0061FF 0%, #60EFFF 100%);
}

.certificates__item-image.violet {
  background: linear-gradient(180deg, #9430CE 0%, #7B3DE5 100%);
}

.certificates__item-image.orange {
  background: linear-gradient(180deg, #FF0F7B 0%, #F89B29 100%);
}

.certificates__item-image.yellow {
  background: linear-gradient(180deg, #FF930F 0%, #CFC929 100%);
}

.certificates__item-image.green {
  background: linear-gradient(180deg, #57EBDE 0%, #8CD312 100%);
}

.certificates__item-image {
  margin: 0;
  padding: 0;
  color: #FFF;
  text-align: center;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  width: 1600px;
  height: 1200px;
}

.certificates__item-image h1 {
  font-size: 80px;
  font-weight: bold;
  line-height: 90px;
}

.certificates__item-image .container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding-left: 50px;
  gap: 10px;
}

.certificates__item-image .container .left {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.certificates__item-image .container .right {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  flex-direction: column;
}

.certificates__item-image .content {
  font-size: 51px;
  font-weight: 500;
}

.certificates__item-image .content__name {
  margin-top: 50px;
  line-height: 45px;
}

.certificates__item-image .content span {
  font-size: 38px;
  font-weight: 400;
}

.certificates__item-image .content__matic {
  font-size: 52px;
  font-weight: 800;
  margin: 40px 0;
}

.certificates__item-image .content__all-matic {
  font-size: 52px;
  font-weight: 800;
}

.certificates__item-image .line {
  width: 173px;
  height: 4px;
  background-color: #FFF;
  margin: 60px auto;
}

.certificates__item-image.orange .content__right-bar .circle {
  background: linear-gradient(180deg, #FE2270 0%, #FC5254 100%);
}

.certificates__item-image.violet .content__right-bar .circle {
  background: linear-gradient(180deg, #8F32D2 0%, #8A36D8 100%);
}

.certificates__item-image.blue .content__right-bar .circle {
  background: linear-gradient(180deg, #0B72FF 0%, #2DA4FF 100%);
}

.certificates__item-image.yellow .content__right-bar .circle {
  background: linear-gradient(180deg, #F89A12 0%, #E8AC1B 100%);
}

.certificates__item-image.green .content__right-bar .circle {
  background: linear-gradient(180deg, #5EE7C1 0%, #70DF7D 100%);
}

.certificates__item-image .content__right-bar .circle {
  width: 492px;
  height: 492px;
  border-radius: 100%;
  position: absolute;
  top: -300px;
  box-sizing: border-box;
  padding: 36px;
}

.certificates__item-image .content__right-bar .inner-circle {
  background-color: #FFF;
  border-radius: 100%;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 75px;
}

.certificates__item-image .content__right-bar .avatar {
  border-radius: 100%;
  width: 100%;
  height: 100%;
}

.certificates__item-image .content__right-bar .avatar img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.certificates__item-image .content__right-bar {
  position: relative;
  background-color: #FFF;
  padding: 300px 70px 120px 70px;
  box-sizing: content-box;
  width: 492px;
  backdrop-filter: blur(25px);
  border-radius: 200px 200px 0 0;
}

.certificates__item-image.violet .content__right-bar .text {
  color: #7B3DE5;
}

.certificates__item-image.orange .content__right-bar .text {
  color: #FF0F7B;
}

.certificates__item-image.blue .content__right-bar .text {
  color: #0061FF;
}

.certificates__item-image.yellow .content__right-bar .text {
  color: #FF930F;
}

.certificates__item-image.green .content__right-bar .text {
  color: #57EBDE;
}

.certificates__item-image .content__right-bar .text {
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
}

.certificates__item-image .content__right-bar span {
  text-transform: none;
}

.certificates__item-image .content__right-bar .description {
  color: #000;
  text-align: center;
  font-size: 48px;
  font-weight: 700;
  font-family: Caveat;
  opacity: 0.67;
  margin-top: 50px;
}

.certificates__item-image .water-text {
  color: #FFF;
  font-family: Inter;
  font-size: 26px;
  font-weight: 800;
  width: 1100px;
  position: absolute;
  transform: rotate(-90deg);
  bottom: 575px;
  left: -500px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.certificates__item {
  max-width: 600px;
  border-radius: 25px;
  overflow: hidden;
  opacity: 0.8;
  cursor: pointer;
  transition: ease-in-out 0.2s;
}

.certificates__item:hover {
  opacity: 1;
}

.certificates__item img {
  max-width: 100%;
  max-height: 100%;
}
</style>