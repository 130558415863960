<script>
import axios from "axios";
import {mapState} from "vuex";

export default {
  data() {
    return {
      userCity: null,
      cityList: null,
      mode: null,
      user: {},
      search: '',
    }
  },
  computed: mapState(['lang']),
  watch: {
    lang() {
      this.getCityList();
    },
  },
  async created() {
    await setTimeout(async () => {
      this.user = await this.getUser();

      if (this.user?.city_id) {
        return;
      }

      this.requestGeoPosition();
    }, 500);
  },
  methods: {
    requestGeoPosition() {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(this.getUserCity, () => {
          this.getCityList();
          this.mode = 'select';
        });
      } else {
        console.log("Геолокация не поддерживается в вашем браузере");
      }
    },
    getUserCity(position) {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      this.getCityFromCoords(latitude, longitude);
    },

    async getUser() {
      return await axios.get(`${process.env.VUE_APP_SERVER}/api/user/getuser/${this.$store.state.wallet.address.toLowerCase()}`, {
        params: {
          sender: this.$store.state.wallet.address.toLowerCase()
        }
      }).then(({ data }) => {
        return data;
      })
    },

    async saveCity(city_id) {
      await axios.post(`${process.env.VUE_APP_SERVER}/api/user/update/${this.$store.state.wallet.address.toLowerCase()}/city`, {
          city_id,
      }).then(() => {
        this.$emit('change', city_id);
      });
    },

    getCityList(name = this.search) {
      const lang = this.$store.state.lang;

      // Отправляем GET-запрос
      axios.get(`${process.env.VUE_APP_SERVER}/api/tag/cities`, {
        params: {
          lang,
          startWith: name,
        }
      })
          .then(({ data }) => {
              this.cityList = data;
          })
          .catch(error => {
            console.error('Произошла ошибка при запросе к geonames API:', error);
          });
    },

    getCityFromCoords(latitude, longitude) {
      const lang = this.$store.state.lang;

      // Отправляем GET-запрос
      axios.get(`${process.env.VUE_APP_SERVER}/api/tag/coord`, {
        params: {
          lat: latitude,
          lng: longitude,
          lang,
        }
      }).then(({ data }) => {
        if (data) {
          this.userCity = data;
          this.mode = 'request';
        } else {
          this.getCityList();
          this.mode = 'select';
        }
      }).catch(() => {
          this.getCityList();
          this.mode = 'select';
        });
    }
  }
}

</script>

<template>
<div>
  <div class="request-city" v-if="userCity && mode == 'request'" >
    <button class="close" @click="mode = null">
      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 26 26">
        <path d="M 21.734375 19.640625 L 19.636719 21.734375 C 19.253906 22.121094 18.628906 22.121094 18.242188 21.734375 L 13 16.496094 L 7.761719 21.734375 C 7.375 22.121094 6.746094 22.121094 6.363281 21.734375 L 4.265625 19.640625 C 3.878906 19.253906 3.878906 18.628906 4.265625 18.242188 L 9.503906 13 L 4.265625 7.761719 C 3.882813 7.371094 3.882813 6.742188 4.265625 6.363281 L 6.363281 4.265625 C 6.746094 3.878906 7.375 3.878906 7.761719 4.265625 L 13 9.507813 L 18.242188 4.265625 C 18.628906 3.878906 19.257813 3.878906 19.636719 4.265625 L 21.734375 6.359375 C 22.121094 6.746094 22.121094 7.375 21.738281 7.761719 L 16.496094 13 L 21.734375 18.242188 C 22.121094 18.628906 22.121094 19.253906 21.734375 19.640625 Z"></path>
      </svg>
    </button>

    {{ this.$store.state.translation[this.$store.state.lang].alerts.yourCityQuestion }} <b>
    {{ userCity.name }}
  </b>?

    <div class="request-city__buttons">
      <button class="blueButton" @click="saveCity(userCity.geonameId); mode = null">{{ this.$store.state.translation[this.$store.state.lang].alerts.right }}</button>
      <button class="other" @click="getCityList(this.userCity); mode = 'select'">
        {{ this.$store.state.translation[this.$store.state.lang].alerts.selectOther }}
      </button>
    </div>
  </div>

  <div class="request-city" v-else-if="mode == 'select'">
    <button class="close" @click="mode = null">
      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 26 26">
        <path d="M 21.734375 19.640625 L 19.636719 21.734375 C 19.253906 22.121094 18.628906 22.121094 18.242188 21.734375 L 13 16.496094 L 7.761719 21.734375 C 7.375 22.121094 6.746094 22.121094 6.363281 21.734375 L 4.265625 19.640625 C 3.878906 19.253906 3.878906 18.628906 4.265625 18.242188 L 9.503906 13 L 4.265625 7.761719 C 3.882813 7.371094 3.882813 6.742188 4.265625 6.363281 L 6.363281 4.265625 C 6.746094 3.878906 7.375 3.878906 7.761719 4.265625 L 13 9.507813 L 18.242188 4.265625 C 18.628906 3.878906 19.257813 3.878906 19.636719 4.265625 L 21.734375 6.359375 C 22.121094 6.746094 22.121094 7.375 21.738281 7.761719 L 16.496094 13 L 21.734375 18.242188 C 22.121094 18.628906 22.121094 19.253906 21.734375 19.640625 Z"></path>
      </svg>
    </button>

    {{ this.$store.state.translation[this.$store.state.lang].alerts.selectCity }}
    <input type="text" @input="getCityList()" v-model="search" :placeholder="this.$store.state.translation[this.$store.state.lang].alerts.inputCityName">

    <div class="request-city__list" v-if="cityList">
      <button class="request-city__item"
        v-for="city in cityList" :key="city.geonameId"
        @click="saveCity(city.geonameId); mode = null">
        {{ city.name }} <span>{{ city.adminName1 }}</span>
      </button>
    </div>
  </div>
</div>
</template>

<style>
.request-city {
  background: var(--sub-bg-color);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  padding: 20px;
  position: fixed;
  top: 90px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 99;
  border-radius: 10px;
  font-size: 18px;
  text-align: center;
  min-width: 300px;
}

.request-city input {
  border: none;
  border-radius: 5px;
  font-size: 16px;
  background: rgba(0, 0, 0, 0.1);
  outline: none;
  padding: 7px 15px;
  display: block;
  margin: 10px auto;
}

.request-city__list {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 15px;
}

.request-city__item {
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 16px;
  text-align: start;
  padding-bottom: 5px;
  transition: ease-in-out 0.2s;
  white-space: nowrap;
}

.request-city__item span {
  opacity: 0.7;
  font-size: 14px;
  display: block;
}

.request-city__item:hover {
  padding-left: 5px;
  cursor: pointer;
}

.request-city__buttons {
  margin-top: 30px;
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

.request-city__buttons .blueButton {
  padding: 7px 15px;
  min-width: 120px;
  font-size: 16px;
}

.request-city__buttons .other {
  background: none;
  outline: none;
  border: none;
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;
  transition: ease-in-out 0.2s;
}

.request-city__buttons .other:hover {
  opacity: 0.8;
}

.request-city .close {
  background: transparent;
  border: none;
  outline: none;
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 16px;
  cursor: pointer;
  transition: ease-in-out 0.2s;
}

.request-city .close:hover svg {
  opacity: 0.7;
}

.request-city .close svg {
  fill: var(--main-color);
  width: 15px;
  height: 15px;
}
</style>