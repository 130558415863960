<script>
import axios from "axios";
import {useToast} from "vue-toastification";

export default {
  data() {
    return {
      show: false,
      data: {},
      toast: useToast(),
    }
  },
  methods: {
    open(show = true) {
      this.show = show;
    },
    hide(event) {
      if (event.target.classList.contains('organization-modal')) {
        this.show = false;
      }
    },
    save() {
      if (!this.data.name || !this.data.address || !this.data.description) {
        this.toast.error(this.$store.state.translation[this.$store.state.lang].addOrganization.errorData);
        return;
      }

      axios.post(`${process.env.VUE_APP_SERVER}/api/application/create`, this.data).then(({ data }) => {
       console.log(data);
       this.toast.success(this.$store.state.translation[this.$store.state.lang].addOrganization.success);
      }).catch(error => {
        console.log(error);
        this.toast.error(this.$store.state.translation[this.$store.state.lang].addOrganization.errorResponse);
      })
    }
  }
}
</script>

<template>
  <div class="organization-modal" v-if="show" @click="hide">
    <div class="organization-modal__inner">
      <div class="organization-modal__title">
        {{ $store.state.translation[this.$store.state.lang].addOrganization.title }}
        <span>
        {{ $store.state.translation[this.$store.state.lang].addOrganization.subtitle[0] }}<br>
          {{ $store.state.translation[this.$store.state.lang].addOrganization.subtitle[1] }}
        </span>
      </div>

      <div class="organization-modal__content">
        <div class="organization-modal__group">
          <label for="name">
            *
            {{ $store.state.translation[this.$store.state.lang].addOrganization.name }}
          </label>
          <input type="text" v-model="data.name" id="name"
                 :placeholder="$store.state.translation[this.$store.state.lang].addOrganization.namePlaceholder">
        </div>

        <div class="organization-modal__group">
          <label for="address">
            *
            {{ $store.state.translation[this.$store.state.lang].addOrganization.address }}
          </label>
          <input type="text" id="address" v-model="data.address" :placeholder="$store.state.translation[this.$store.state.lang].addOrganization.defaultPlaceholder">
        </div>

        <div class="organization-modal__group">
          <label for="description">
            *
            {{ $store.state.translation[this.$store.state.lang].addOrganization.description }}
          </label>
          <textarea rows="5" v-model="data.description" id="description" :placeholder="$store.state.translation[this.$store.state.lang].addOrganization.defaultPlaceholder"></textarea>
        </div>

        <div class="organization-modal__group">
          <label for="">
            {{ $store.state.translation[this.$store.state.lang].addOrganization.site }}
          </label>
          <input type="text" v-model="data.site" id="site" :placeholder="$store.state.translation[this.$store.state.lang].addOrganization.defaultPlaceholder">
        </div>

        <div class="organization-modal__group">
          <label for="">{{ $store.state.translation[this.$store.state.lang].addOrganization.vkontakte }}</label>
          <input type="text" v-model="data.vkontakte" id="vkontakte" :placeholder="$store.state.translation[this.$store.state.lang].addOrganization.defaultPlaceholder">
        </div>

        <div class="organization-modal__group">
          <label for="">Instagram</label>
          <input type="text" v-model="data.instagram" id="instagram" :placeholder="$store.state.translation[this.$store.state.lang].addOrganization.defaultPlaceholder">
        </div>

        <div class="organization-modal__group">
          <label for="yandex">{{ $store.state.translation[this.$store.state.lang].addOrganization.yandex }}</label>
          <input type="text" v-model="data.yandex" id="yandex" :placeholder="$store.state.translation[this.$store.state.lang].addOrganization.defaultPlaceholder">
        </div>

        <div class="organization-modal__group">
          <label for="tiktok">TikTok</label>
          <input type="text" v-model="data.tiktok" id="tiktok" :placeholder="$store.state.translation[this.$store.state.lang].addOrganization.defaultPlaceholder">
        </div>

        <div class="organization-modal__group">
          <label for="youtube">YouTube</label>
          <input type="text" v-model="data.youtube" id="youtube" :placeholder="$store.state.translation[this.$store.state.lang].addOrganization.defaultPlaceholder">
        </div>
      </div>

      <div class="organization-modal__buttons">
        <button class="blueButton" @click="show = false; save()">{{ this.$store.state.translation[this.$store.state.lang].addOrganization.save }}</button>
        <button class="other" @click="show = false">
          {{ this.$store.state.translation[this.$store.state.lang].alerts.cancel }}
        </button>
      </div>
    </div>
  </div>
</template>

<style>
.organization-modal {
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(15px);
  z-index: 999;
}

.organization-modal__inner {
  background-color: var(--main-bg-color);
  border-radius: 15px;
  width: 600px;
  padding: 35px 20px;
  overflow-y: scroll;
  max-height: 90%;
}

.organization-modal__title {
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
}

.organization-modal__title span {
  font-size: 15px;
  display: block;
  line-height: 19px;
  margin-top: 10px;
  font-weight: 500;
}

.organization-modal__content {
  padding-top: 25px;
}

.organization-modal__group {
  margin: 15px 0;
  display: flex;
  flex-direction: column;
}

.organization-modal__group label {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.1875rem;
  margin-bottom: 5px;
}

.organization-modal__group input, .organization-modal__group textarea {
  outline: none;
  border: 1px solid var(--color-8);
  border-radius: 0.625rem;
  padding: 10px 15px;
  resize: vertical;
  background-color: var(--main-bg-color);
}

.organization-modal__buttons {
  margin-top: 30px;
  display: flex;
  gap: 10px;
}

.organization-modal__buttons .blueButton {
  padding: 7px 15px;
  min-width: 120px;
  font-size: 16px;
}

.organization-modal__buttons .other {
  background: none;
  outline: none;
  border: none;
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;
  transition: ease-in-out 0.2s;
}

.organization-modal__buttons .other:hover {
  opacity: 0.8;
}

/* custom scrollbar */
.organization-modal__inner::-webkit-scrollbar {
  width: 18px;
}

.organization-modal__inner::-webkit-scrollbar-track {
  background-color: transparent;
}

.organization-modal__inner::-webkit-scrollbar-thumb {
  background-color: var(--color-3);
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
  transition: ease-in-out 0.2s;
}

.organization-modal__inner::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-2);
}
</style>