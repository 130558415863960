<script>
import axios from "axios";

export default {
  data() {
    return {
      show: false,
      url: ''
    }
  },
  methods: {
    open(url) {
      this.url = url;
      this.show = true;
    },
    hide(event) {
      if (event.target.classList.contains('certificate-modal')) {
        this.show = false;
      }
    },
    download() {
      axios.get(this.url, {
        responseType: "blob"
      }).then(({ data }) => {
        const blob = new Blob([data]);
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = 'certificate.png';
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
    }
  }
}
</script>

<template>
  <div class="certificate-modal" v-if="show" @click="hide">
    <div class="certificate-model__inner">
      <button @click="download" class="certificate__download"><i class="ri-download-line"></i></button>
      <img :src="url" alt="">
    </div>
  </div>
</template>

<style scoped>
.certificate-modal {
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(15px);
  z-index: 999;
}

.certificate-model__inner {
  position: relative;
  background-color: var(--main-bg-color);
  border-radius: 15px;
  width: 900px;
  height: 675px;
  overflow: hidden;
}

.certificate-model__inner img {
  width: 100%;
  height: 100%;
}

.certificate__download {
  background-color: var(--main-bg-color);
  border-radius: 100%;
  padding: 10px;
  color: var(--main-color);
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  position: absolute;
  cursor: pointer;
  top: 20px;
  right: 20px;
  transition: ease-in-out 0.2s;
}

.certificate__download:hover {
  opacity: 0.8;
}

@media screen and (max-width: 920px) {
  .certificate-model__inner {
    width: 90%;
  }

  .certificate-model__inner img {
    width: 100%;
    height: 100%;
  }
}
</style>