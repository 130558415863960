<template>
  <div v-if="collections && collections.length > 0">
    <div class="container hotTitle">
      <h3>{{ this.$store.state.translation[this.$store.state.lang].topcollection.title }}</h3>
    </div>

    <div class="grid-wrapper">
      <div class="grid-container">
        <CollectionCard
            v-for="item of collections"
            :key="item.id"
            :icon="item.image?.image"
            :price="item.price"
            :title="item.name"
            :owner="item.owner?.username"
            :chainId="item.chain_id"
            :address="item.address"
            :blockchainId="item.blockchain_id"
        />
      </div>
      <VueAwesomePaginate
          v-if="collections.length > 0"
          :total-items="totalItems"
          :items-per-page="itemsPerPage"
          :max-pages-shown="maxPagesShown"
          v-model="currentPage"
          :on-click="pageClickHandler"
          paginate-buttons-class="btn"
          active-page-class="btn-active"
          back-button-class="back-btn"
          next-button-class="next-btn"
      />
    </div>
  </div>
</template>

<script>
import CollectionCard from "./CollectionCard.vue";
import { callViewContractFunction } from "kasatkin-crypto-core/src";
import connect from "../../config.crypto";
import UnlimitedCollectionABI from "../ABI/UnlimitedCollectionABI.json";
import { ref } from "vue";
import { VueAwesomePaginate } from "vue-awesome-paginate";

export default {
    components: {
        CollectionCard,
        VueAwesomePaginate,
    },

    data() {
        return {
            currentPage: ref(1),
            totalItems: 0,
            itemsPerPage: 15,
            maxPagesShown: 5,
            collections: [],
        };
    },

    methods: {
        async getCryptoData(address, abi, cryptoFunc, args, rpc) {
            let cryptoData = await callViewContractFunction({
                from: this.$store.state.wallet.address,
                to: address,
                contractabi: abi,
                function: cryptoFunc,
                args: args,
                rpc: rpc,
            });
            return cryptoData;
        },

        async loadData(page = 1) {
            const limit = this.itemsPerPage;
            const topCollections = await this.requestData(
                "get",
                {},
                `collection/hot?page=${page}&limit=${limit}`,
                "json"
            );
            this.collections = topCollections.rows;
            this.totalItems = topCollections.count;

            for (let collection of this.collections) {
                const metaInfo = await this.getCryptoData(
                    collection.address,
                    UnlimitedCollectionABI,
                    "checkMetadataInfo",
                    [collection.blockchain_id],
                    connect.wallets[0].rpc[connect.wallets[0].chain[0]]
                );
                collection.price = metaInfo[1];
                if (!collection.image) {
                    collection.image = { image: await this.getMetaImage(metaInfo[0], collection.blockchain_id) };
                }
            }
        },

        async getMetaImage(tokenURL, blockchainId) {
            try {
                tokenURL = tokenURL.split("/");
                tokenURL[tokenURL.length - 1] = tokenURL[tokenURL.length - 1].split(".");
                tokenURL[tokenURL.length - 1][0] = blockchainId;
                tokenURL[tokenURL.length - 1] = tokenURL[tokenURL.length - 1].join(".");
                tokenURL = tokenURL.join("/");
                let metadata = await fetch(tokenURL.replace("ipfs://", "https://nftstorage.link/ipfs/"));
                metadata = await metadata.json();
                return metadata.image.replace("ipfs://", "https://nftstorage.link/ipfs/");
            } catch (e) {
                console.error(e.message);
                return null;
            }
        },

        async requestData(method, bodyItem, func, code) {
            const body = JSON.stringify(bodyItem);
            let dataRequest = undefined;
            if (method == "get") {
                dataRequest = {
                    method: method,
                    headers: {
                        "content-type": "application/json",
                    },
                };
            } else if (method == "post") {
                dataRequest = {
                    method: method,
                    headers: {
                        "content-type": "application/json",
                    },
                    body: body,
                };
            }
            if (dataRequest) {
                let response = await fetch(`${process.env.VUE_APP_SERVER}/api/${func}`, dataRequest);
                if (response.ok) {
                    if (code == "json") {
                        try {
                            return await response.json();
                        } catch {
                            return false;
                        }
                    } else {
                        try {
                            return await response.text();
                        } catch {
                            return false;
                        }
                    }
                } else {
                    console.log("Error HTTP: " + response.status);
                }
            } else {
                console.log("request method not found");
            }
        },

        async getMetaData(i) {
            let tokenURL = "";
            try {
                tokenURL = "";
                tokenURL = tokenURL.split("/");
                tokenURL[tokenURL.length - 1] = tokenURL[tokenURL.length - 1].split(".");
                tokenURL[tokenURL.length - 1][0] = this.nfts[i].id;
                tokenURL[tokenURL.length - 1] = tokenURL[tokenURL.length - 1].join(".");
                tokenURL = tokenURL.join("/");
                let imageURL = await fetch(tokenURL.replace("ipfs://", "https://nftstorage.link/ipfs/"));
                imageURL = await imageURL.json();
                this.nfts[i].title = imageURL.name;
                if (this.nfts[i].icon != imageURL.image.replace("ipfs://", "https://nftstorage.link/ipfs/")) {
                    this.nfts[i].icon = imageURL.image.replace("ipfs://", "https://nftstorage.link/ipfs/");
                }
            } catch {
                return false;
            }
        },

        pageClickHandler(page) {
            this.loadData(page);
        },
    },

    mounted() {
        this.loadData();
    },

    beforeUnmount() {},
};
</script>

<style>
.hotTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 4rem auto 2.1875rem auto;
  text-transform: uppercase;
}

.hotTitle {
  margin: 4rem auto 1rem auto;
}

.grid-wrapper {
    margin-bottom: 6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.grid-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;

    padding: 0 4%;
    width: 100%;
    max-width: calc(1680px + 1920px * 0.04 * 2);
    margin: 0 auto;
}

.item {
    width: 100%;
}

.pagination-container {
    display: flex;
    column-gap: 3px;
    margin-top: 20px;
    list-style: none;
}
.grid-wrapper .pagination-container .btn {
    height: 40px;
    width: 40px;
    border: none !important;
    margin-inline: 3px;
    cursor: pointer;
    background-color: var(--color-11);
    border-radius: 7px;
}
.btn.btn-active {
    background-color: var(--color-13) !important;
    color: white;
}

@media screen and (max-width: 1540px) {
    .grid-container {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media screen and (max-width: 1100px) {
    .grid-container {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width: 800px) {
    .grid-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 520px) {
    .grid-container {
        grid-template-columns: repeat(1, 1fr);
    }
}
</style>
