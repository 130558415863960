<template>
    <div class='v-select-lang'>
        <div class='nav-button-lang' @click="openOptions" :class='{activeLang: areOptionsVisible}'>

            <div>{{ $store.state.lang.toUpperCase() }}</div>
            
            <svg width="9" height="4" viewBox="0 0 9 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 0.5L4.30476 3.33265C4.41711 3.42895 4.58289 3.42895 4.69524 3.33265L8 0.5" stroke="white" stroke-linecap="round"/>
            </svg>

        </div>

        <transition name="component-fade">
            <div class='options' v-if='areOptionsVisible'>
                <p v-for='option in options' :key='option' @click='selectOption(option)' class='noClick nav-button-lang'>
                    {{ option.toUpperCase() }}
                </p>
            </div>
        </transition>
    </div>
</template>

<script>

export default {
    name: 'SelectLang',

    data() {
        return {
            areOptionsVisible: false,
            options: Object.keys(this.$store.state.translation)
        }
    },
    methods: {
        closeWindow(event) {
            if (event.type != 'scroll') {
                if (event.target.closest('.v-select-lang') == null) {
                    this.areOptionsVisible = false
                }
            } else {
                this.areOptionsVisible = false
            }
            
        },
        selectOption(option) {
            this.$store.dispatch('setLang', option.toLowerCase())
            document.cookie = encodeURIComponent('lang') + '=' + encodeURIComponent(option.toLowerCase())
            document.documentElement.lang = option.toLowerCase()
            this.areOptionsVisible = false
        },
        openOptions() {
            this.areOptionsVisible = !this.areOptionsVisible
        },
    },

    mounted() {
        let cookieLang = document.cookie.split(';').map((i) => i.trim()).find((j) => j.slice(0, 4) == 'lang') ? document.cookie.split(';').map((i) => i.trim()).find((j) => j.slice(0, 4) == 'lang').slice(5) : undefined
    
        if (cookieLang != undefined) {
            if (Object.keys(this.$store.state.translation).indexOf(cookieLang) != -1) {
                this.$store.dispatch('setLang', cookieLang)
                document.documentElement.lang = cookieLang
            } else {
                this.$store.dispatch('setLang', 'ru')
                document.cookie = encodeURIComponent('lang') + '=' + encodeURIComponent('ru')
                document.documentElement.lang = 'ru'
            }
        } else {
            this.$store.dispatch('setLang', 'ru')
            document.cookie = encodeURIComponent('lang') + '=' + encodeURIComponent('ru')
            document.documentElement.lang = 'ru'
        }

        document.addEventListener('click', this.closeWindow, true)
        document.addEventListener('scroll', this.closeWindow, true)
    },

    beforeUnmount() {
        document.removeEventListener('click', this.closeWindow, true)
        document.removeEventListener('scroll', this.closeWindow, true)
    },
}
</script>


<style scoped>
.v-select-lang {
    position: relative;
    width: min-content;
    cursor: pointer;
    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;

    background: var(--sub-bg-color);
    backdrop-filter: blur(2px);
    border-radius: 10px;
    height: 2.875rem;

    display: flex;
    align-items: center;
    justify-content: center;
}

.v-select-lang p {
    margin: 0;
}

.options {
    position: absolute;
    top: 3.125rem;
    left: 0;
    backdrop-filter: blur(2px);
    border-radius: 10px;
    background: var(--sub-bg-color);
    width: 100%;
}

.activeLang {
    opacity: .5;
}

.activeLang svg {
    transform: scale(-1);
}

.nav-button-lang {
    padding: 8px 16px;
    border-radius: 4px;
    transition: opacity .2s;
    max-width: 93px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    gap: .5rem;
    height: -webkit-fill-available;
}

.nav-button-lang div {
    transition: color .2s;
}

.nav-button-lang:hover {
    opacity: .5;
}

.nav-button-lang svg {
    transition: all .5s;
}

.nav-button-lang svg path {
    transition: all .2s;
    stroke: var(--main-color);
}

.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}

.component-fade-enter-from,
.component-fade-leave-to {
  opacity: 0;
}

</style>