<script>
export default {
  data() {
    return {
      show: false,
      url: ''
    }
  },
  methods: {
    open(url) {
      this.url = url;
      this.show = true;
    },
    hide(event) {
      if (event.target.classList.contains('video-modal')) {
        this.show = false;
      }
    }
  }
}
</script>

<template>
  <div class="video-modal" v-if="show" @click="hide">
    <div class="video-model__inner">
      <iframe width="900" height="600"
              :src="url"
              title="YouTube video player" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen>
      </iframe>
    </div>
  </div>
</template>

<style scoped>
.video-modal {
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(15px);
  z-index: 999;
}

.video-model__inner {
  background-color: var(--main-bg-color);
  border-radius: 15px;
  width: 900px;
  height: 600px;
  overflow: hidden;
}

@media screen and (max-width: 920px) {
  .video-model__inner {
    width: 90%;
    height: 60vw;
  }

  .video-model__inner iframe {
    width: 100%;
    height: 100%;
  }
}
</style>