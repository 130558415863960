<template>
    <div>
        <div class="firstScreen">
            <div class="container">
                <div class="first-screen">
                    <div>
                      <div class="first-screen__title">
                          {{ this.$store.state.translation[this.$store.state.lang].firstScrin.title }}
                          <span>{{ this.$store.state.translation[this.$store.state.lang].firstScrin.titleSecond }}</span>
                      </div>
                      <div class="first-screen__text">
                          {{ this.$store.state.translation[this.$store.state.lang].firstScrin.text }}
                      </div>

                      <div class="first-screen__video">
                        <iframe width="600" height="340" :src="video.main"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen>

                        </iframe>
                      </div>

                      <div class="first-screen__links">
                        <div class="left">
                          <a href="javascript:void(0)" @click="$refs.video.open(this.video.first)">
                            <i class="ri-external-link-fill"></i>
                            {{ $store.state.translation[this.$store.state.lang].firstScrin.linkFirst }}
                          </a>
                          <a href="javascript:void(0)" @click="$refs.video.open(this.video.second)">
                            <i class="ri-external-link-fill"></i>
                            {{ $store.state.translation[this.$store.state.lang].firstScrin.linkSecond }}
                          </a>
                        </div>
                        <div class="right">
                          <a href="javascript:void(0)" @click="$refs.video.open(this.video.three_mobile)" v-if="isMobile()">
                            <i class="ri-external-link-fill"></i>
                            {{ $store.state.translation[this.$store.state.lang].firstScrin.linkThree }}
                          </a>
                          <a href="javascript:void(0)" @click="$refs.video.open(this.video.three_pc)" v-else>
                            <i class="ri-external-link-fill"></i>
                            {{ $store.state.translation[this.$store.state.lang].firstScrin.linkThree }}
                          </a>

                          <a href="javascript:void(0)" @click="$refs.video.open(this.video.four_mobile)" v-if="isMobile()">
                            <i class="ri-external-link-fill"></i>
                            {{ $store.state.translation[this.$store.state.lang].firstScrin.linkFour }}
                          </a>

                          <a href="javascript:void(0)" @click="$refs.video.open(this.video.four_pc)" v-else>
                            <i class="ri-external-link-fill"></i>
                            {{ $store.state.translation[this.$store.state.lang].firstScrin.linkFour }}
                          </a>
                        </div>
                      </div>
                    </div>
                    <!-- <button class="maintext2 mainButton">{{ this.$store.state.translation[this.$store.state.lang].firstScrin.button }}</button> -->
                </div>
                <img class="firstScreenImg" src="../assets/mainScreen/1.png" />
              
            </div>
            <div class="overlow"></div>
        </div>


      <top-users style="overflow: hidden" />
      <hot-accounts style="overflow: hidden" />
      <last-accounts style="overflow: hidden" />
      <rent-collection />
      <top-collections />
      <urgently-collections  />
      <fee-collections />

      <video-modal ref="video" />
    </div>
</template>

<script>
import HotAccounts from "../components/HotAccounts.vue";
import TopUsers from "../components/TopUsers.vue"
import LastAccounts from "@/components/LastAccounts.vue";
import TopCollections from "../components/TopCollections.vue";
import UrgentlyCollections from "@/components/UrgentlyCollections.vue";
import RentCollection from "../components/RentCollection.vue";
import VideoModal from "@/components/VideoModal.vue";

import { checkConnect, switchChain } from "kasatkin-crypto-core/src";
import connect from "../../config.crypto";
import FeeCollections from "@/components/FeeCollections.vue";

export default {
    components: {
      FeeCollections,
        HotAccounts,
        LastAccounts,
        TopCollections,
        UrgentlyCollections,
        RentCollection,
        TopUsers,
        VideoModal
    },

    data() {
        return {
            activeSlide: 0,
            video: {
              main: process.env.VUE_APP_VIDEO_MAIN,
              first: process.env.VUE_APP_VIDEO_BLOGERS_LINK,
              second: process.env.VUE_APP_VIDEO_BLOGERS_LINK,
              three_pc: process.env.VUE_APP_VIDEO_HOW_EARN_PC_LINK,
              three_mobile: process.env.VUE_APP_VIDEO_HOW_EARN_MOBILE_LINK,
              four_pc: process.env.VUE_APP_VIDEO_REGISTRATION_PC_LINK,
              four_mobile: process.env.VUE_APP_VIDEO_REGISTRATION_MOBILE_LINK
            }
        };
    },

    methods: {
        next() {
            let appWidth = document.querySelector("#app").offsetWidth;
            let sliderWidth = document.querySelector("#sliderItems").offsetWidth;
            let slideWidth = document.querySelector("#sliderItem0").offsetWidth;

            let transform = document.querySelector("#sliderItems").style.transform;
            if (transform != "") {
                transform = Number(transform.split("(")[1].slice(0, -3));
            } else {
                transform = 0;
            }

            if (transform - slideWidth - 30 > -sliderWidth + appWidth) {
                this.activeSlide++;
                this.changeSlide(this.activeSlide * (slideWidth + 30));
            } else {
                this.changeSlide(sliderWidth - appWidth * 0.92 - 30);
            }
        },
        previous() {
            if (this.activeSlide > 0) {
                let appWidth = document.querySelector("#app").offsetWidth;
                let sliderWidth = document.querySelector("#sliderItems").offsetWidth;
                let slideWidth = document.querySelector("#sliderItem0").offsetWidth;

                let transform = document.querySelector("#sliderItems").style.transform;
                if (transform != "") {
                    transform = Number(transform.split("(")[1].slice(0, -3));
                } else {
                    transform = 0;
                }

                if (transform > -sliderWidth + appWidth) {
                    this.activeSlide--;
                }
                this.changeSlide(this.activeSlide * (slideWidth + 30));
            }
        },
        changeSlide(width) {
            document.querySelector("#sliderItems").style.transform = `translateX(-${width}px)`;
        },

        async cheakConnected() {
            for (let wallet of connect.wallets) {
                const isConnect = await checkConnect({ wallet: wallet.name });
                if (isConnect) {
                    this.$store.state.wallet.address = isConnect.accounts[0];
                    this.$store.state.wallet.chainId = isConnect.chainId;
                    if (!connect.wallets[0].chain.includes(isConnect.chainId)) {
                        await switchChain({
                            wallet: connect.wallets[0].name,
                            chainId: connect.wallets[0].chain[0],
                            rpc: connect.wallets[0].rpc[connect.wallets[0].chain[0]],
                            params: connect.wallets[0].addChainParams[connect.wallets[0].chain[0]],
                        });
                        this.$store.state.wallet.chainId = connect.wallets[0].chain[0];
                    }
                    break;
                }
            }
        },
      isMobile() {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          return true
        } else {
          return false
        }
      }
    },

    created() {
        this.cheakConnected();
    },
};
</script>

<style scoped>
.firstScreen {
    background: url(../assets/mainScreen/bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 1000vh;
    max-height: 67.25rem;
    padding-top: 5.375rem;

    position: relative;
}

.first-screen {
}

.first-screen__title {
  font-size: 3.5rem;
  font-weight: 800;
  margin-bottom: 10px;
}

.first-screen__title span {
  display: block;
  font-size: 3.5rem;
}

.first-screen__text {
  font-weight: 500;
  font-size: 1.0625rem;
  line-height: 139.02%;
}

.first-screen__video {
  border-radius: 15px;
  overflow: hidden;
  opacity: 0.85;
  transition: ease-in-out 0.2s;
  max-width: 600px;
  max-height: 340px;
  margin: 35px 0;
}

.first-screen__video:hover {
  opacity: 1;
}

.firstScreen .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    color: #ffffff;
}

.firstScreenImg {
    width: 60%;
    max-width: 935px;
}

.mainButton {
    font-size: 1.25rem;
    margin-top: 3.4rem;
}

.first-screen__links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 600px;
}

.first-screen__links a {
  display: block;
  color: #ffffff;
  font-size: 0.95rem;
  margin-top: 10px;
  text-transform: none;
  text-decoration: none;
  background-color: var(--sub-bg-color);
  color: var(--main-color);
  padding: 6px 15px;
  border-radius: 5px;
  text-transform: lowercase;
  opacity: 0.8;
  transition: ease-in-out 0.2s;
}

.first-screen__links a:hover {
  opacity: 1;
}

.overlow {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 3rem;
    background: var(--main-bg-color);
    background: linear-gradient(rgba(0, 0, 0, 0), var(--main-bg-color));
}

@media screen and (max-width: 1200px) {
    .firstScreen .container {
        justify-content: start;
        flex-direction: column-reverse;
        margin-top: 0;
    }

   .firstScreenImg {
     max-width: 550px;
     margin-bottom: 20px;
   }

    .texttitle .maintext2 {
        margin-top: 0.7rem;
    }

    .firstScreen {
        max-height: 90rem;
      text-align: center;
    }

    .first-screen__video {
      margin: 35px auto;
    }

    .first-screen__links {
      margin: 0 auto;
    }

    .maintext2.mainButton {
        margin-top: 2.5rem;
    }
}

@media screen and (max-width: 1000px) {
    .firstScreen {
        max-height: 85rem;
    }

    .firstScreenImg {
        width: 70%;
    }

    .first-screen iframe {
      max-width: 600px;
      max-height: 320px;
    }

    .first-screen {
      max-width: 600px;
      text-align: center;
    }

    .first-screen__title {
      font-size: 40px;
      text-align: center;
    }

    .first-screen__title span {
      font-size: 40px;
    }
}

@media screen and (max-width: 800px) {
    .firstScreen {
        max-height: 85rem;
    }

    .texttitle {
        max-width: 32rem;
    }

    .firstScreenImg {
        width: 75%;
    }
}

@media screen and (max-width: 600px) {
    .firstScreen {
        max-height: 75rem;
    }

  .first-screen iframe {
    max-width: 100%;
    max-height: 40vw;
  }

  .first-screen {
    max-width: 90%;
  }

  .first-screen__title {
    font-size: 28px;
  }

  .first-screen__title span {
    font-size: 28px;
  }

  .first-screen__links {
    flex-direction: column;
    gap: 20px;
  }

  .first-screen__links a {
    font-size: 1em;
  }

  .firs-screen__links a {
    min-width: 100%;
  }
}

@media screen and (max-width: 400px) {
  .firstScreen {
    max-height: 68rem;
  }

  .first-screen__title {
    font-size: 24px;
  }

  .first-screen__title span {
    font-size: 24px;
  }
}
</style>
